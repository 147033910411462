import styled from 'styled-components'

const ItemStyles = styled.div`
	background: #1568bf;
	border: 1px solid #1568bf;
	border-radius: 4px;
	margin: 5px;
	padding: 5px 10px;
	display: flex;
	cursor: pointer;
	

	.tagName {
		color: #fff;
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		margin-right: 10px;
	}

	.removeButton {
		width: 16px;
		height: 100%;
		border: none;
		outline: none;
		background: none;
		color: #fff;
		cursor: pointer;
		font-size: 16px;
		font-weight: 600;
	}
`

export default ItemStyles
