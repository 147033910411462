import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { BeatLoader } from "react-spinners";
import StarRatings from "react-star-ratings";

// assets
import {
  ActiveIcon,
  PendingActivationIcon,
  PendingDeactivationIcon,
  TokenIcon
} from "../../../../assets/Icons";

// components
import BenefitSubscribeButtonsContainer from "./BenefitSubscribeButtons/BenefitSubscribeButtonsContainer";
import LeaveReviewForm from "../../../../components/forms/review/LeaveReviewForm";
import {
  openRatingModalAnim,
  closeRatingModalAnim
} from "../../../../components";

// style component
import BenefitSubscribeStyles from "./BenefitSubscribeStyles";
import { subscribe } from "../../../../services/loadingOverride.styles";

// services
import { getUrl } from "../../../../services/imageHandler";
import * as api from "../../../../services/api/employee/employeeBenefits.services";

// actions
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";

// builder constants
import { BenefitExpirationTypeEnum } from "../../../../constants/benefit.constants";
import {
  ACTIVE,
  PENDING_ACTIVATION,
  PENDING_DEACTIVATION
} from "../../../../builders/benefitGroups/benefitGroup.status";

// Utils
import { renderLocations } from "../../../../renderers/locations.renderer";
import { doesDescriptionExist } from "../../../../services/textEditor.utils";
import { isEmpty } from "../../../../services/general.utils";
import { calculatePriceWithVat } from "../../../../services/benefit.utils";

// Renderers
import { renderCategories } from "../../../../renderers/categories.renderer";
import { getBenefitsForBenefitGroup } from "../../../../services/api/employee/employeeBenefits.services";
import { toast } from "react-toastify";

const BenefitSubscribe = ({
  benefitGroup,
  isPreview = false,
  maxHeight = "90vh",
  user,
  usedBenefits,
  setUsedBenefits,
  setShowPDFBill,
  setVoucherReimbursementData,
  priceRange,
  chosenBenefitExpirationTypes
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
  const [benefits, setBenefits] = useState([]);
  const isMounted = useRef(false);

  const fetchBenefits = async () => {
    setIsLoading(true);
    const response = await api.getBenefitsForBenefitGroup(
      benefitGroup.id,
      priceRange,
      chosenBenefitExpirationTypes
    );

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching benefits failed."
      );
    }

    setBenefits(response.benefits);
    setIsLoading(false);
  };

  const getVoucherPrices = benefit => {
    if (benefit.expiration === BenefitExpirationTypeEnum.VOUCHER) {
      const voucherPrices = benefit.vouchers.map(voucher => {
        return user.companyVatEnabled && benefit.vat
          ? calculatePriceWithVat(voucher.price, benefit.vat)
          : voucher.price;
      });
      const maxTokenPrice =
        voucherPrices.length > 0 ? Math.max(...voucherPrices) : 0;
      const minTokenPrice =
        voucherPrices.length > 0 ? Math.min(...voucherPrices) : 0;
      if (maxTokenPrice === minTokenPrice) {
        return `${maxTokenPrice}`;
      } else {
        return `${minTokenPrice} - ${maxTokenPrice}`;
      }
    }
  };

  /**
   * Returns benefit price.
   * If benefit is subscribed to, returns subscribed price.
   * If not, returns default price.
   * @param {Benefit} benefit
   * @returns {number}
   */
  const getBenefitPrice = benefit => {
    if (benefit.isSubscribed) {
      return benefit.subscribedPrice;
    }

    const benefitPrice =
      user.companyVatEnabled && benefit.vat
        ? calculatePriceWithVat(benefit.tokenPrice, benefit.vat)
        : benefit.tokenPrice;
    if (
      benefitGroup.doesBenefitGroupHaveInstalments &&
      benefitGroup.enableInstalments &&
      benefit.expiration === "one time" &&
      benefit.instalments
    ) {
      return `${benefit.instalments} x ${Math.floor(
        benefitPrice / benefit.instalments
      )}`;
    }

    return benefitPrice;
  };

  /**
   * Renders benefit status with appropriate icon and color scheme.
   * @param {string} status
   * @param {string} statusPreview
   * @param {boolean} shouldBeIndicatedAsActive
   * @param {boolean} isSubscribed
   * @returns {HTMLDivElement}
   */
  const renderBenefitStatus = (
    status,
    statusPreview,
    shouldBeIndicatedAsActive,
    isSubscribed
  ) => {
    if (isSubscribed && status === ACTIVE) {
      return (
        <div className="benefitStatusActive">
          <ActiveIcon opacity="1" fill="#41C264" /> {statusPreview}
        </div>
      );
    }
    if (status === PENDING_DEACTIVATION && shouldBeIndicatedAsActive) {
      return (
        <div className="benefitStatusPendingDeactivation">
          <PendingDeactivationIcon opacity="1" fill="#C53C3C" /> {statusPreview}
        </div>
      );
    }
    if (isSubscribed && status === PENDING_ACTIVATION) {
      return (
        <div className="benefitStatusPendingActivation">
          <PendingActivationIcon opacity="1" fill="#84988E" /> {statusPreview}
        </div>
      );
    }
  };

  /**
   * Returns class names for benefit card based on benefit status.
   * @param {Benefit} benefit
   * @returns {string}
   */
  const getClassNameForBenefitCard = benefit => {
    if (
      benefit.shouldBeIndicatedAsActive &&
      benefitGroup.status === PENDING_DEACTIVATION
    ) {
      return "benefiItemCard benefitStatusPendingDeactivationIndicator";
    }

    if (benefit.isSubscribed && benefitGroup.status === ACTIVE) {
      return "benefiItemCard benefitStatusActiveIndicator";
    }

    if (benefit.isSubscribed && benefitGroup.status === PENDING_ACTIVATION) {
      return "benefiItemCard benefitStatusPendingActivationIndicator";
    }

    return "benefiItemCard";
  };

  const openModal = () => {
    setIsOpenRatingModal(!isOpenRatingModal);

    setTimeout(() => {
      openRatingModalAnim();
    }, 100);
  };

  const closeModal = () => {
    setIsOpenRatingModal(!isOpenRatingModal);

    setTimeout(() => {
      closeRatingModalAnim();
    }, 350);
  };

  /**
   * Sorts benefits from benefit group by subscription status.
   * Subscribed benefits should always be on top.
   * @returns {Benefit[]}
   */
  const sortedBenefits = () => {
    return (
      !isEmpty(benefits) &&
      benefits.sort(
        (a, b) =>
          Number(b.isSubscribed) - Number(a.isSubscribed) ||
          Number(b.shouldBeIndicatedAsActive) -
            Number(a.shouldBeIndicatedAsActive)
      )
    );
  };

  useEffect(() => {
    isMounted.current = true;
    fetchBenefits();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <BenefitSubscribeStyles maxHeight={maxHeight}>
      <BeatLoader
        css={subscribe}
        size={25}
        color="#123abc"
        loading={isLoading}
      />
      <div className="imageContainer">
        {benefitGroup && benefitGroup.photo && (
          <img
            src={getUrl(
              !isEmpty(benefitGroup.companyId)
                ? benefitGroup.provider.photo
                : benefitGroup.photo,
              "url3x"
            )}
            alt="test"
            className="image"
            crossOrigin="anonymous"
          />
        )}
      </div>
      <div className="topContainer">
        <div className="providerImageOuterCircle">
          <div className="providerImageInnerCircle">
            {benefitGroup && (
              <img
                src={
                  !isEmpty(benefitGroup.provider)
                    ? getUrl(benefitGroup.provider.photo)
                    : getUrl(benefitGroup.photo)
                }
                alt="provider logo"
                className="providerLogo"
                crossOrigin="anonymous"
              />
            )}
          </div>
        </div>
        <h2 className="title">
          {benefitGroup && benefitGroup.name && benefitGroup.name}
        </h2>
        <div className="categoryTag">
          {renderCategories(benefitGroup.categories)}
        </div>
        <div className="ratingContainer">
          <div className="starContainer">
            <span className="total">
              {benefitGroup.numberReviews
                ? (
                    benefitGroup.sumRatings / benefitGroup.numberReviews
                  ).toFixed(1)
                : 0}
            </span>
            <StarRatings
              numberOfStars={1}
              rating={
                benefitGroup.numberReviews
                  ? benefitGroup.sumRatings / benefitGroup.numberReviews
                  : 0
              }
              starDimension="18px"
              starSpacing="0px"
              starEmptyColor="#EAEAEA"
              starRatedColor="#FFD402"
            />
            <span className="reviewsNumber">
              {" "}
              ({benefitGroup.numberReviews} ratings)
            </span>
          </div>
          {/**
           * When the client decides to add comments, change the text of the link to View reviews. The link opens the same modal.
           */}
          {usedBenefits?.length > 0 && !isOpenRatingModal && (
            <div
              onClick={() => {
                openModal(benefitGroup.id);
              }}
              className="link"
            >
              Rate this benefit
            </div>
          )}
        </div>
        {isOpenRatingModal && (
          <div id="ratingModalCard" className="ratingCardContainer">
            {usedBenefits?.length > 0 && (
              <LeaveReviewForm
                usedBenefits={usedBenefits}
                setUsedBenefits={setUsedBenefits}
                closeModal={closeModal}
              />
            )}
          </div>
        )}
        {doesDescriptionExist(benefitGroup.description) ? (
          <div className="description">
            {ReactHtmlParser(benefitGroup.description)}
          </div>
        ) : (
          <p className="summary">{benefitGroup.summary}</p>
        )}
        {renderLocations(
          benefitGroup.cities,
          benefitGroup.isRemote,
          user?.employee?.cities
        )}
      </div>
      <div className="benefitItemsContainer">
        {!isEmpty(benefits) &&
          benefits &&
          sortedBenefits().map(benefit => (
            <div
              className={getClassNameForBenefitCard(benefit)}
              key={benefit.id}
            >
              {
                <div className="benefitStatusWrap">
                  {renderBenefitStatus(
                    benefitGroup.status,
                    benefitGroup.statusPreview,
                    benefit.shouldBeIndicatedAsActive,
                    benefit.isSubscribed
                  )}
                  {user.companyVatEnabled && !isEmpty(benefit.vat) && (
                    <div className="vatDisclaimer">
                      Price includes VAT of {benefit.vat}%.
                    </div>
                  )}
                </div>
              }
              <div className="labelValues">
                <p className="benefitName">{benefit.title}</p>
                {!isEmpty(benefit.quantity) &&
                  !isEmpty(benefit.totalQuantityRemaining) &&
                  !benefit.isSubscribed && (
                    <div className="remainingQuantity">
                      {benefit.totalQuantityRemaining} Remaining
                    </div>
                  )}
                {!isEmpty(benefit.quantity) &&
                  isEmpty(benefit.totalQuantityRemaining) &&
                  !benefit.isSubscribed && (
                    <div className="remainingQuantity">
                      {benefit.quantity} Remaining
                    </div>
                  )}
              </div>
              {doesDescriptionExist(benefit.description) ? (
                <div className="benefitDescription">
                  {ReactHtmlParser(benefit.description)}
                </div>
              ) : (
                <p className="benefitSummary">{benefit.summary}</p>
              )}
              <div className="benefitExpirationAndTokens">
                <p className="duration">{benefit.expiration}</p>
                <div className="tokenContainer">
                  <TokenIcon />
                  <p className="tokens">
                    {benefit.expiration === "voucher"
                      ? getVoucherPrices(benefit)
                      : getBenefitPrice(benefit)}
                  </p>
                </div>
              </div>
              <BenefitSubscribeButtonsContainer
                benefit={benefit}
                status={benefitGroup.status}
                benefitGroupType={benefitGroup.type}
                isPreview={isPreview}
                setIsLoading={setIsLoading}
                enableInstalments={benefitGroup.enableInstalments}
                setShowPDFBill={setShowPDFBill}
                setVoucherReimbursementData={setVoucherReimbursementData}
              />
            </div>
          ))}
      </div>
    </BenefitSubscribeStyles>
  );
};

BenefitSubscribe.propTypes = {
  benefitGroupId: PropTypes.number,
  closeModal: PropTypes.func,
  isSubscribedBenefits: PropTypes.bool,
  getUserBenefitGroups: PropTypes.func,
  resetBenefits: PropTypes.func
};

const mapStateToProps = state => {
  return {
    benefitGroup:
      state.employeeBenefitsPage.pageFrontEndStates.chosenBenefitGroup,
    user: state.app.user,
    priceRange: state.employeeBenefitsPage.filters.chosenPriceRange,
    chosenBenefitExpirationTypes: state.employeeBenefitsPage.filters.expirations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeBenefitGroupModal: () =>
      dispatch(actionCreators.closeBenefitGroupModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitSubscribe);
