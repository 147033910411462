import styled from "styled-components";

export const ButtonContainerStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    
    .pageButtonsContainer {
      display: flex;
    }
  }

  .mobileFilterButton {
    display: none;
  }

  @media only screen and (max-width: 1099px) {
    .mobileFilterButton {
      display: unset;
    }

    .mobileButton {
      white-space: nowrap;
      width: auto;
    }
  }

  @media only screen and (max-width: 767px) {
    .mobileFilterButton {
      display: block;
      width: auto !important;
      margin-bottom: 10px;
      font-size: 11px;
    }

    .mobileButton {
      white-space: nowrap;      
      margin-bottom: 10px;
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 500px) {
    .mobileFilterButton {
      font-size: 10px;
      padding: 5px;
      border-radius: 20px;
    }

    .mobileButton {
      font-size: 10px;
      padding: 8px;
    }
  }

  @media only screen and (max-width: 340px) {
    .mobileButton {
      font-size: 9px;
      padding: 5px;
      margin-right: 5px;
    }
  }
`;
