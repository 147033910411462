import * as actionTypes from "../../constants/actionTypes/hr/employees.constants";

export const setHrEmployees = (employees, count) => {
  return { type: actionTypes.SET_HR_EMPLOYEES, employees, count };
};

export const setHrEmployeePage = page => {
  return { type: actionTypes.SET_PAGE, page };
};

export const setHrEmployeeSearch = search => {
  return { type: actionTypes.SET_SEARCH_CHANGE, search };
};

export const toggleSidebarMenu = isSidebarOpen => {
  return { type: actionTypes.TOGGLE_SIDEBAR_MENU, isSidebarOpen };
};
