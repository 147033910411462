import styled from 'styled-components'

const CheckboxHolder = styled.div`
	width: ${({width}) => width || '20px'};
	height: ${({height}) => height || '21px'};
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border: solid 2px ${({ isChecked, disabled }) => {
		if (disabled) {
			return '#B3B3B3'
		}
		if (isChecked) {
			return '#1568bf'
		}
		return '#B3B3B3'
	}}; 
	cursor: pointer;
	background: ${({ isChecked, disabled }) => {
		if (disabled) {
			return '#fff'
		}
		if (isChecked) {
			return '#1568bf'
		}
		return '#fff'
	}};

	.checkbox {
		width: ${({width}) => width || '20px'};
		height: ${({height}) => height || '21px'};
		opacity: 0;
		cursor: ${({cursor }) => cursor || 'pointer'};
		position: absolute;
	}
`

export default CheckboxHolder
