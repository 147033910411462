import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import { Card, Button, SelectInput, InputLabel } from "../../../../components";
import { formatDateForSearch } from "../dashboad.services";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";
import { reportTypeDropdownOptions } from "../../../SuperAdminPages/Dashboard/reportTypeDropdownOptions";
import { BASE_CONTENT_URL } from "../../../../constants/api.constants";

const UsageReportGeneratorStyles = styled.div`
  margin: 0 20px 20px 0;
  order: 7;
  width: 400px;
`;

const RefundReport = () => {
  const [date, setDate] = useState(new Date());
  const [searchDateString, setSearchDateString] = useState(
    formatDateForSearch(new Date())
  );
  const [reportType, setReportType] = useState(undefined);

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const getStatistics = async () => {
    const response = await dashboardService.getRefundStatistics(
      searchDateString,
      reportType
    );

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching Report Statistics failed."
      );
    }

    window.location = `${BASE_CONTENT_URL}/zip/${response.data.fileName}`;
  };

  return (
    <UsageReportGeneratorStyles>
      <Card>
        <div className="titleContainer">
          <DatePicker
            className="datePickerInput"
            id="usageReportGeneratorId"
            selected={date}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onChange={chosenDate => handleDateChange(chosenDate)}
            value={date}
            maxDate={new Date()}
          />
          <h3 className="title">Generate refund report</h3>
        </div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <InputLabel label="Select report type" margin="0 0 5px 0" />
          <SelectInput
            name="reportTypes"
            options={reportTypeDropdownOptions}
            selectedValues={reportTypeDropdownOptions}
            value={reportType}
            handleChange={option => setReportType(option.id)}
            customId="reportTypeId"
            shouldHaveHight={false}
            shouldHaveFullWidth
            customHeight="27px"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            margin="0"
            width="200px"
            disabled={!searchDateString || !reportType}
            onClick={() => getStatistics()}
          >
            Download
          </Button>
        </div>
      </Card>
    </UsageReportGeneratorStyles>
  );
};

export default RefundReport;
