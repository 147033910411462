import React, { useState, useEffect, useRef, useReducer } from "react";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import SearchIcon from '@material-ui/icons/Search';
// components
import {
  Card,
  Header,
  Button,
  Table,
  TableHead,
  TableRow,
  SearchInput,
  MultiselectInput,
  Modal,
  openModalAnim,
  closeModalAnim,
  DisableUserModal,
  DisableUsersModal,
  CheckboxInput,
  ListItem,
} from "../../../../components";
import AddUser from "../AddUser/AddUser";
// assets
import { TrashIconSvg, AddIconSvg, FilterIconSvg } from "../../../../assets/Icons";
// styles
import UsersListStyles from "./UsersListStyles";
import { override } from "../../../../services/loadingOverride.styles";
// builder config
import { tableHeadBuilder } from "./builder/tableHeadBuilder";
// builder reducer
import { tableHeaderReducer } from "./builder/tableHeadReducer";
// services
import * as actionCreators from "../../../../actions/admin/users.actions";
import * as api from "../../../../services/api/admin/users.service";
import * as companiesService from '../../../../services/api/admin/companies.service';
import { openAddUserForm } from "../users.services";
import { getOrderColumns } from "../../../../services/getOrderColumns";
import { Company, UserRole } from "../../../../models/domain";

// Utils
import { isEmpty } from "../../../../services/general.utils";

const LIMIT = 35;

const UsersList = ({
  users,
  page,
  count,
  search,
  setUsers,
  setPage,
  onSearchChange
}) => {
  const [isAddUserVisible, setIsAddUserVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [chosenCompanies, setChosenCompanies] = useState([]);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [chosenCompanyIds, setChosenCompanyIds] = useState([]);
  const [roles, setRoles] = useState([]);
  const [chosenRoles, setChosenRoles] = useState([]);
  const [chosenRoleIds, setChosenRoleIds] = useState([]);
  const [disableUserPopup, setDisableUserPopup] = useState(false);
  const [disableUsersPopup, setDisableUsersPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedMap, setCheckedMap] = useState(new Map());

  const [tableHeaders, dispatch] = useReducer(
    tableHeaderReducer,
    tableHeadBuilder
  );

  const isMounted = useRef(true);
  const areFiltersLoaded = useRef(false);

  /**
   * Opens disable user popup.
   * Sets selected user id to state.
   * @param {number} userId 
   */
  const openDisableUserPopup = (userId) => {
    setSelectedUserId(userId)
    setDisableUserPopup(true);

    setTimeout(() => {
      openModalAnim();
    }, 500);
  }

  /**
   * Opens disable users popup.
   * Sets selected users ids to state.
   */
  const openDisableUsersPopup = () => {
    if (selectedNumber < 1) {
      return toast.error('Please select users.');
    };

    setDisableUsersPopup(true);

    setTimeout(() => {
      openModalAnim();
    }, 500);
  }

  const fetchCompanies = async () => {
    const response = await api.getCompanyOptions(companiesPage);
    if (response.hasError) {
      return toast.error(response.errorMessage ? response.errorMessage : "Fetching companies failed")
    }

    setCompanies(response.companies);
    setTotalPages(response.pages);
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
   */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = currentPage += 1;

    if (newPage > totalPages) return;

    setCompaniesPage(newPage);

    const response = await api.getCompanyOptions(newPage);
    if (response.hasError) {
      return toast.error(response.errorMessage ? response.errorMessage : "Fetching companies failed")
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companies, ...response.companies];
      setTimeout(() => {
        setCompanies(newCompanies);
      }, 100)
    }, 100)
  }


  const fetchRoles = async () => {
    const response = await api.getRolesForDropdown();
    if (response.hasError) {
      return toast.error(response.errorMessage ? response.errorMessage : "Fetching roles failed")
    }

    setRoles(response);
  }

  const fetchFilters = async () => {
    await fetchCompanies();
    await fetchRoles();

    areFiltersLoaded.current = true;
  }

  const getUsers = async (searchText = search, companyIds = chosenCompanyIds, roleIds = chosenRoleIds) => {
    const response = await api.getUsers({
      limit: LIMIT,
      page,
      searchText,
      orderColumns: getOrderColumns(tableHeaders),
      companyIds,
      roleIds
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : "Fetching users failed"
      );
    }

    setUsers(response);
    setIsLoading(false);

    if (isEmpty(response.users) && page > 1) {
      setPage(1);
    }
  };

  useEffect(() => {
    getUsers();
    fetchFilters();
  }, [page, tableHeaders]);

  useEffect(() => {
    if (areFiltersLoaded.current === true)
      getUsers();
  }, [chosenCompanyIds, chosenRoleIds]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [users.length])


  /**
    * Handles select of element from multiselect.
    * Sets selected elements' ids to state.
    * @param {Company} options - Selected options, emmited from multiselect.
  */
  const handleSetChosenCompanies = (options) => {
    let selectedCompanyIds = [];

    if (options && options.length) {
      selectedCompanyIds = options.map(category => category.id);
    }

    setChosenCompanies(options);
    setChosenCompanyIds(selectedCompanyIds);
  };

  /**
    * Handles select of element from multiselect.
    * Sets selected elements' ids to state.
    * @param {UserRole} options - Selected options, emmited from multiselect.
  */
  const handleSetChosenRoles = (options) => {
    let selectedRoleIds = [];

    if (options && options.length) {
      selectedRoleIds = options.map(role => role.id);
    }

    setChosenRoles(options);
    setChosenRoleIds(selectedRoleIds);
  };

  const removeCompanyFilter = (company) => {
    const chosenCompaniesFilters = chosenCompanies.filter(el => { return company.id !== el.id });
    const chosenCompaniesFiltersIds = chosenCompanyIds.filter(el => { return company.id !== el });

    setChosenCompanies(chosenCompaniesFilters);
    setChosenCompanyIds(chosenCompaniesFiltersIds);
  }

  const removeRoleFilter = (role) => {
    const chosenRolesFilters = chosenRoles.filter(el => { return role.id !== el.id });
    const chosenRolesFiltersIds = chosenRoleIds.filter(el => { return role.id !== el });

    setChosenRoles(chosenRolesFilters);
    setChosenRoleIds(chosenRolesFiltersIds);
  }

  const removeAllFilters = () => {
    setChosenRoles([]);
    setChosenRoleIds([]);
    setChosenCompanies([]);
    setChosenCompanyIds([]);
  };

  const toggleSortFunc = id => dispatch({ type: id });

  /**
   * Handles the behavior when users on the active page change
   * Users that are not included in the checkedMap are added with the value false
   * If at least one user is checked on the active page, set isChecked to true, otherwise false.
   */
  useEffect(() => {
    const checked = new Map(checkedMap);
    let numberOfSelectedOnPage = 0;
    users.forEach(user => checked.has(user.id) 
        ? checked.get(user.id) && numberOfSelectedOnPage++ 
        : !user.isDisabled && checked.set(user.id, false)      
    ); 
    
    numberOfSelectedOnPage > 0 ? setIsChecked(true) : setIsChecked(false);
    setCheckedMap(checked);
  }, [users]);

  /**
   * Handles the behavior when the checkedMap is change
   * If at least one user is checked on the active page, set isChecked to true, otherwise false.
   */
  useEffect(() => {
    let numberOfSelectedOnPage = 0;
    users.forEach(user => checkedMap.has(user.id) && checkedMap.get(user.id) && numberOfSelectedOnPage++);
    
    numberOfSelectedOnPage > 0 ? setIsChecked(true) : setIsChecked(false);
  }, [checkedMap]);

  const handleCheckboxOnChange = () => {
    const checked = new Map(checkedMap);
    !isChecked 
      ? users.forEach(user => !user.isDisabled && checked.set(user.id, true)) 
      : users.forEach(user => !user.isDisabled && checked.set(user.id, false));

    let total = 0;
    for (const x of checked.values()) {
      x && total ++;
    };
    
    setSelectedNumber(total);
    setCheckedMap(checked);
    setIsChecked(!isChecked);
  }

  return (
    <UsersListStyles selectedColor = {(selectedNumber > 0) ? "#668CCC" : "#1D1D1D"} >
      <div className="usersListContainer">
        <Header headerTitle="Platform Users" />
        <div className="usersListContent">
          {isLoading ? (
            <div
              style={{
                height: "calc(100vh - 180px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <BeatLoader
                css={override}
                size={25}
                color="#123abc"
                loading={isLoading}
              />
            </div>
          ) : (
            <Card id="usersList" padding="0 0 30px 0">
              <div style={{
                display: "flex",
                alignItems: 'center',
                justifyContent: "space-between",
                padding: '16px',
                flexWrap: "wrap",
                gap: "20px",
              }}>
                <div className="buttonsWrapper">
                  <div className="searchIcon">
                    <SearchIcon style={{color: "lightgray"}}/>
                  </div>
                  <SearchInput
                    fetchData={getUsers}
                    setSearch={onSearchChange}
                    search={search}
                    setPagginationPage={setPage}
                    style={{ maxWidth: 400 }}                    
                    searchWrapperWidth='61%'
                    customClass='searchEmployees'
                    placeholder='Search users'
                    placeholderColor='777777'
                  />
                   <button className="filterButton" onClick={() => setIsShowFilters(!isShowFilters)}>
                    <FilterIconSvg/>
                    <p>Filters</p>                 
                  </button>
                  {isShowFilters && (
                    <div className="filtersContainer mobile">
                      <div className="closeFiltersButton" onClick={() => setIsShowFilters(false)}> X </div>
                      <div className="companiesFilterWrapper">
                        <MultiselectInput
                          name="companies"
                          options={companies}
                          shouldHaveFullHeight={true}
                          placeholder='Filter by companies'
                          handleChange={option => handleSetChosenCompanies(option)}
                          selectedValues={chosenCompanies}
                          fetchMoreData={refetchCompanies}
                        />
                      </div>
                      <div className="companiesFilterWrapper">
                        <MultiselectInput
                          name="roles"
                          options={roles}
                          shouldHaveFullHeight={true}
                          placeholder='Filter by roles'
                          handleChange={option => handleSetChosenRoles(option)}
                          selectedValues={chosenRoles}
                        />
                      </div>
                    </div>
                  )}                  
                </div>                
                <Button
                  padding="9px 25px"
                  margin="0"
                  onClick={() => openAddUserForm(setIsAddUserVisible)}
                >
                  <p className="addSign">+</p>
                  <p className="buttonText">Add User</p>
                </Button>
              </div>
              {(!isEmpty(chosenCompanies) || !isEmpty(chosenRoles)) &&
                <div className="appliedFiltersContainer">
                  <div className="appliedFilters">
                    {!isEmpty(chosenCompanies) && chosenCompanies.map((company, idx) => (
                      <div key={idx} className="appliedFilterPill">                        
                        <span>
                          {company.name}
                        </span>
                        <span
                          className="removeFilter"
                          onClick={() => removeCompanyFilter(company)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                    {!isEmpty(chosenRoles) && chosenRoles.map((role, idx) => (
                      <div key={idx} className="appliedFilterPill">                        
                        <span>
                          {role.name}
                        </span>
                        <span
                          className="removeFilter"
                          onClick={() => removeRoleFilter(role)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                  </div>
                  <button className="clearButton" onClick={removeAllFilters}>Clear all</button>
                </div>
              }
              <div className="secondMenu">
                <div className="flexContainer">
                  <div className="flexContainer">                    
                    <CheckboxInput 
                      onChange={handleCheckboxOnChange}
                      isChecked={isChecked} 
                      name="selected"
                      width="18px"
                      height="18px"
                      cursor="pointer"
                    />
                    <p className="selected">{selectedNumber} Selected</p>
                  </div>
                  {/* 
                  <div className="flexContainer" onClick={() =>console.log('add')}>
                    <div className="plus">
                      <AddIconSvg/>
                    </div>
                    <p className="addBonus"> Add Bonus </p>
                  </div>
                  */}
                  <button className="flexContainer trashButton" onClick={openDisableUsersPopup}>                    
                    <TrashIconSvg width = '17px' stroke = '#CC6666'/>                    
                    <p>Delete</p>
                  </button>
                </div>
              </div>
              <div className="tableHead">
                {tableHeaders.map(
                  ({
                    id,
                    headName,
                    isSortedUp,
                    isSortable,
                    width,
                    justifyContent,
                    margin
                  }) => (
                    <TableHead
                      key={id}
                      id={id}
                      tableHeadColName={headName}
                      isSortedUp={isSortedUp}
                      colWidth={width}
                      justifyContent={justifyContent}
                      margin={margin}
                      isSortable={isSortable}
                      toggleSortFunc={toggleSortFunc}
                    />
                  )
                )}
              </div>
              <div className="usersListWrapper">
                {!isEmpty(users) && users.map(({
                  id,
                  firstName,
                  lastName,
                  roles,
                  role,
                  employeeId,
                  email,
                  company,
                  isDisabled,
                  employee
                }) => (
                  <ListItem
                    key={id}
                    fullName={`${firstName} ${lastName}`}
                    isRowDisabled={isDisabled}
                    roles={roles.map(role => role.name).join(', ')}
                    company={!isEmpty(company) ? company.name : 'No company'}
                    email={email}
                    userId={id}
                    employeeId={employeeId}
                    openDisableUserPopup={openDisableUserPopup}
                    employeeData={employee}
                    fetchData={getUsers}
                    setSelectedNumber={setSelectedNumber}
                    checkedMap={checkedMap}
                    setCheckedMap={setCheckedMap}
                  />
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10
                }}
              >
                <Pagination
                  currentPage={page}
                  totalSize={count}
                  sizePerPage={LIMIT}
                  changeCurrentPage={page => setPage(page)}
                  theme="border-bottom"
                />
              </div>
            </Card>
          )}
          {isAddUserVisible && (
            <div id="addUserForm">
              <AddUser
                fetchUsers={getUsers}
                setIsAddUserVisible={setIsAddUserVisible}
              />
              <div style={{ height: 30 }} />
            </div>
          )}
          {disableUserPopup && (
            <Modal isCloseVisable={true} closeModal={() => setDisableUserPopup(false)}>
              <DisableUserModal
                userId={selectedUserId}
                closeModal={() => setDisableUserPopup(false)}
                fetchData={getUsers}
              />
            </Modal>
          )}
          {disableUsersPopup && (
            <Modal isCloseVisable={true} closeModal={() => setDisableUsersPopup(false)}>
              <DisableUsersModal
                closeModal={() => setDisableUsersPopup(false)}
                fetchData={getUsers}
                userIdsMap={checkedMap}
                text="users"
                setSelectedNumber={setSelectedNumber}
              />
            </Modal>
          )}
        </div>
      </div>
    </UsersListStyles>
  );
};

const mapStateToProps = state => {
  return {
    users: state.usersPage.users,
    page: state.usersPage.page,
    count: state.usersPage.count,
    search: state.usersPage.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUsers: payload => dispatch(actionCreators.setUsers(payload)),
    setPage: payload => dispatch(actionCreators.setPage(payload)),
    onSearchChange: payload => dispatch(actionCreators.onSearchChange(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
