import { Company } from "../../../models/domain";
import { CreateCompanyPayload } from "../../../models/server/payloads/CreateCompany.payload";
import CompaniesResponse from "../../../models/server/responses/Companies.response";
import { apiService } from "../api.service";

import { isEmpty } from "../../general.utils";

export const getCompanies = async ({
  limit = 20,
  page,
  searchText,
  orderColumns
} = {}) => {
  let queryParams = "";

  if (limit) {
    queryParams += `limit=${limit}`;
  }

  if (page) {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}page=${page}`;
  }

  if (searchText) {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}search=${searchText}`;
  }

  let orderQuery = "";
  if (orderColumns && orderColumns.length > 0) {
    orderQuery = orderColumns.reduce(
      (acc, column) =>
        `${!isEmpty(acc) ? `${acc}&` : ""}order=${column.field} ${column.isSortedUp ? "ASC" : "DESC"
        }`,
      ""
    );
  }

  if (!isEmpty(orderQuery)) {
    queryParams += `${!isEmpty(queryParams) && "&"}${orderQuery}`;
  }

  const response = await apiService.get(`/company/get?${queryParams}`);

  if (response.hasError) return response;

  return new CompaniesResponse(response);
};

export const createCompany = async newCompany => {
  let payloadData = new CreateCompanyPayload(newCompany);

  if (payloadData.paymentDeadlineDaysNumber) {
    const response = await apiService.post(
      "/company/create",
      payloadData.payload
    );

    if (response.hasError) return response;

    return new Company(response.data);
  }
};

export const getSelectedCompany = async id => {
  const response = await apiService.get(`/company/${id}`);

  if (response.hasError) return response;

  return new Company(response.data);
};

export const updateCompany = async company => {
  let payloadData = new CreateCompanyPayload(company);

  if (payloadData.paymentDeadlineDaysNumber) {
    const response = await apiService.put(
      `/company/update/${company.id}`,
      payloadData.payload
    );

    if (response.hasError) return response;

    return new Company(response.data);
  }
};
