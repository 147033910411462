import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

// Services
import { truncate } from "../../../../../services/truncateString";
// Components
import Card from "../../../../../components/wrapperCardComponent";
import BenefitDetailsActions from "./BenefitDetailsActions/BenefitDetailsActions";
// Icons
import { TokenIcon, DotsIcon } from "../../../../../assets/Icons";

const BenefitDetailsStyles = styled.div`
  width: 328px;
  height: 350px;
  padding: 5px 5px 0px 30px;

  .detailsActionsButton {
    border: 0;
    background-color: white;
  }

  .benefitDetailsContent {
    height: 301px;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .title {
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #373737;
    }

    .__react_component_tooltip.type-dark.border {
      padding: 0;
      min-width: 120px;
      border-radius: 5px;
      border: 1px solid #1568bf !important;
      & > div {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  .description {
    height: 156px;
    line-height: 1.83;
    color: #4b4b4b;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 43px;
  }

  .tokenPriceContainer {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    .tokens {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #373737;
      margin-right: 10px;
    }
  }

  .durationContainer {
    display: flex;
    align-items: center;

    .duration {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #373737;
      margin-right: 10px;
    }
  }

  .draftIndicator {
    display: flex;
    background: #DCDCDC;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #737373;
    padding: 5px;
		position: absolute;
    right: 25px;
  }

  @media screen and (max-width: 1868px) {
    width: 361px;
  }
`;

const BenefitDetails = ({
  openEditBenefit,
  benefitDetails,
  fetchData
}) => {
  const [isActive, setIsActive] = useState(false);
  const myTooltip = useRef();

  const generatePrice = () => {
    let priceString = "";
    if (benefitDetails.expiration === "voucher" && benefitDetails.vouchers) {
      benefitDetails.vouchers.forEach(
        (voucher, i) =>
        (priceString =
          priceString +
          `${voucher.price}${i + 1 === benefitDetails.vouchers.length ? "" : "-"
          }`)
      );
    } else {
      priceString = benefitDetails.tokenPrice;
    }
    return priceString;
  };

  const onClickDots = () => {
    setIsActive(true);
    ReactTooltip.rebuild();
  };

  const handleBlur = () => {
    setIsActive(false);
    ReactTooltip.rebuild();
  }

  return (
    <BenefitDetailsStyles>
      {benefitDetails && (
        <Card
          margin="0"
          padding="20px"
          boxShadow="
						0 2px 4px -1px rgba(0, 0, 0, 0.2),
						0 1px 10px 0 rgba(0, 0, 0, 0.12),
						0 4px 5px 0 rgba(0, 0, 0, 0.14)
					"
        >
          <div className="benefitDetailsContent">
            <div className="titleContainer">
              <h3 className="title">{truncate(benefitDetails.title, 20)}</h3>
              {benefitDetails.isDraft && (
                <span className="draftIndicator">Draft</span>
              )}
              <button
                type="button"
                className="actionsCol detailsActionsButton"
                data-tip={benefitDetails.id}
                data-event={isActive ? "focus" : "click"}
                data-event-off={!isActive ? "focusout" : ""}
                onClick={e => {
                  e.stopPropagation();
                  onClickDots();
                }}
                onBlur={() => handleBlur()}
              >
                <DotsIcon />
              </button>
              <ReactTooltip
                place="bottom"
                effect="solid"
                // eslint-disable-next-line
                ref={ref => (myTooltip.current = ref)}
                className="tooltip"
                getContent={dataTip => {
                  if (!dataTip) {
                    return "";
                  }

                  return (
                    <BenefitDetailsActions
                      openEditBenefit={openEditBenefit}
                      benefitId={benefitDetails.id}
                      isDraft={benefitDetails.isDraft}
                      hideTooltip={() => {
                        myTooltip.current.tooltipRef = null;
                        ReactTooltip.hide();
                      }}
                      fetchData={fetchData}
                    />
                  );
                }}
                border
                clickable
              />
            </div>
            <p className="description">{benefitDetails.summary}</p>
            <div className="tokenPriceContainer">
              <p className="tokens">
                {benefitDetails.expiration === "voucher"
                  ? "Vouchers price:"
                  : "Tokens :"}
              </p>
              <p className="tokens" style={{ fontWeight: "normal" }}>
                {generatePrice()}
              </p>
              <TokenIcon outerFill="#4b4b4b" lineFill="#fff" />
            </div>
            <div className="durationContainer">
              <p className="duration">Duration type :</p>
              <p className="duration" style={{ fontWeight: "normal" }}>
                {benefitDetails.expiration}
              </p>
            </div>
          </div>
        </Card>
      )}
    </BenefitDetailsStyles>
  );
};

BenefitDetails.propTypes = {
  openEditBenefit: PropTypes.func,
  benefitDetails: PropTypes.shape()
};

export default BenefitDetails;
