import User from "../models/domain/User.entity";
import { routesConfig } from "../config/router.config";
import { getItem, setItem } from "../services/localStorage.service";
import { BENEFIT_ROLE } from "../constants/localStorage.constants";
import * as actionTypes from "../constants/actionTypes/app.constants";
import { updateObject } from "../services/updateObject";

const initialState = {
  user: new User(),
  routes: routesConfig[getItem(BENEFIT_ROLE)]
    ? routesConfig[getItem(BENEFIT_ROLE)]
    : routesConfig["public"],
  lang: "en",
  notifications: []
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return login(state, action);
    case actionTypes.SWITCH_USER_ROLE:
      return switchUserRole(state, action);
    case actionTypes.SET_USER:
      return setUser(state, action);
    case actionTypes.FINISH_USER_ONBOARDING:
      return finishUserOnboarding(state, action);
    case actionTypes.FINISH_EMPLOYEE_ONBOARDING:
      return finishEmployeeOnboarding(state, action);
    case actionTypes.SET_USER_TOKENS:
      return setEmployeeTokens(state, action);
    case actionTypes.SET_USER_NOTIFICATIONS:
      return setSystemNotifications(state, action);
    case actionTypes.POP_SYSTEM_NOTIFICATIONS:
      return popSystemNotifications(state, action);
    case actionTypes.USER_ACCEPT_PRIVACY_POLICY:
      return acceptPrivacyPolicy(state, action);
    case actionTypes.LOGOUT:
      return logout(state, action);
    case actionTypes.SYNC_USER:
      return syncUser(state, action);
    case actionTypes.SET_USER_THEMES:
      return setUserThemes(state, action);
  }
  return state;
};

const login = (state, action) => {
  return updateObject(state, {
    user: action.payload,
    routes: routesConfig[action.payload.authenticatedRole.key]
  });
};

/**
 * Enables switching of user roles.
 * Sets user authenticatedRole to new role.
 * Sets routesConfig to new value.
 * @param {object} state 
 * @param {object} payload - Role object, destructured.
 * @returns {object}
 */
const switchUserRole = (state, { roleObject }) => {
  return updateObject(state, {
    user: updateObject(state.user, {
      authenticatedRole: roleObject
    }),
    routes: routesConfig[roleObject.key]
  });
}

const setUser = (state, action) => {
  return updateObject(state, {
    user: action.payload
  });
};

const logout = (state, action) => {
  return updateObject(state, {
    user: new User(),
    routes: routesConfig["public"]
  });
};

const finishUserOnboarding = (state, action) => {
  return updateObject(state, {
    user: updateObject(state.user, {
      isOnboardingDone: true
    })
  });
};

const finishEmployeeOnboarding = (state, action) => {
  return updateObject(state, {
    user: updateObject(state.user, {
      employeeOnboarding: true
    })
  });
};

const acceptPrivacyPolicy = (state, action) => {
  return updateObject(state, {
    user: updateObject(state.user, {
      hasAcceptedPrivacyPolicy: true
    })
  });
};

const setEmployeeTokens = (state, { employeeTokens }) => {
  return updateObject(state, {
    user: updateObject(state.user, {
      usedTokens: employeeTokens.usedTokens,
      availableTokens: employeeTokens.availableTokens,
      remainingTokens: employeeTokens.remainingTokens,
      budgetBreakdown: employeeTokens.budgetBreakdown
    })
  });
};

const setSystemNotifications = (state, { systemNotifications }) => {
  return updateObject(state, {
    notifications: systemNotifications
  });
};

const popSystemNotifications = state => {
  return updateObject(state, {
    notifications: state.notifications.filter(
      (notification, index) => index > 0
    )
  });
};

const syncUser = (state, action) => {
  return updateObject(state, {
    user: updateObject(state.user, {
      isSync: true
    })
  });
};

const setUserThemes = (state, { themes }) => {
  return updateObject(state, {
    user: updateObject(state.user, {
      themes: themes
    })
  });
};

export default appReducer;
