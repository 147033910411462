import React, { useContext, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import styled from "styled-components";

import {
  Header,
  Card,
  SelectInput,
  InputLabel,
  Button,
  Modal,
  openModalAnim,
  closeModalAnim
} from "../../../components";
import PrivacyPolicy from "../../../components/privacyPolicyModal";
import { formatDateForSearch } from "../../HrPages/HrDashboard/dashboad.services";

import { UserContext } from "../../../modules/user/context/user.context";
import * as actionCreators from "../../../actions/admin/companies.actions";

import * as companiesService from "../../../services/api/admin/companies.service";
import * as dashboardService from "../../../services/api/admin/dashboard.service";
import { downloadBlob } from "../../../services/files.utils";
import { reportTypeDropdownOptions } from "./reportTypeDropdownOptions";

const DashboardStyles = styled.section`
  width: calc(100% - 210px);
  margin-left: 210px;
  background: whitesmoke;
  min-height: 100vh;

  .content {
    padding: 0 30px 30px 30px;
  }

  .optionsContainer {
    width: 200px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
  }

  .datePickerInput {
    height: 24px;
    width: 200px;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #d8d8d8;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &:hover {
      border: 1px solid #1568bf;
      z-index: 2;
    }
  }
`;

const Dashboard = ({ companies, setCompanies, user }) => {
  const isMounted = useRef(false);
  const [company, setCompany] = useState(undefined);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [reportType, setReportType] = useState(undefined);
  const [date, setDate] = useState(new Date());
  const [searchDateString, setSearchDateString] = useState(
    formatDateForSearch(new Date())
  );

  // const { user } = useContext(UserContext)

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const getStatistics = async () => {
    const response = await dashboardService.getStatistics(
      company,
      searchDateString,
      reportType
    );

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching statistics failed"
      );
    }

    downloadBlob(response.data, "export.csv", "text/csv;charset=utf-8;");
  };

  const getCompanies = async () => {
    const response = await companiesService.getCompanies({
      page: companiesPage
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching companies failed"
      );
    }

    const formattedResponse = [
      { id: "All Companies", name: "All Companies" },
      ...response.companies
    ];

    setCompanies(formattedResponse);
    setTotalPages(response.pages);
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
   */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = currentPage += 1;

    if (newPage > totalPages) return;

    setCompaniesPage(newPage);

    const response = await companiesService.getCompanies({ page: newPage });
    if (response.hasError) {
      return toast.error(response.errorMessage ? response.errorMessage : "Fetching companies failed")
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companies, ...response.companies];
      setTimeout(() => {
        setCompanies(newCompanies);
      }, 100)
    }, 100)
  }

  useEffect(() => {
    isMounted.current = true;
    getCompanies();
  }, []);

  useEffect(() => {
    if (isMounted.current && user.authenticatedRole && !user.hasAcceptedPrivacyPolicy) {
      setTimeout(() => {
        openModalAnim();
      }, 500);
    }
  }, []);

  return (
    <DashboardStyles>
      <Header headerTitle="Dashboard" />
      <div className="content">
        <div style={{ width: 260 }}>
          <Card>
            <div className="optionsContainer">
              <h1 className="title">Generate usage report</h1>
              <div>
                <InputLabel label="Choose month" margin="0 0 5px 0" />
                <DatePicker
                  className="datePickerInput"
                  id="datePickerTierBudget"
                  selected={date}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  onChange={chosenDate => handleDateChange(chosenDate)}
                  value={date}
                  maxDate={new Date()}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <InputLabel label="Select report type" margin="0 0 5px 0" />
                <SelectInput
                  name="reportTypes"
                  options={reportTypeDropdownOptions}
                  selectedValues={reportTypeDropdownOptions}
                  value={reportType}
                  handleChange={option => setReportType(option.id)}
                  customId="reportTypeId"
                  shouldHaveHight={false}
                  shouldHaveFullWidth
                  customHeight="27px"
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <InputLabel label="Select company" margin="0 0 5px 0" />
                <SelectInput
                  name="companies"
                  options={companies}
                  selectedValues={companies}
                  value={company}
                  handleChange={option => {
                    setCompany(option.id)
                  }}
                  fetchMoreData={refetchCompanies}
                  customId="companyId"
                  shouldHaveHight={false}
                  shouldHaveFullWidth
                  customHeight="27px"
                />
              </div>
              <Button
                disabled={!company || !searchDateString || !reportType}
                onClick={() => getStatistics()}
                margin="20px 0 0 0"
                width="100%"
              >
                Download
              </Button>
            </div>
          </Card>

          {isMounted.current && user.authenticatedRole && !user.hasAcceptedPrivacyPolicy && (
            <Modal isCloseVisable={false}>
              <PrivacyPolicy />
            </Modal>
          )}
        </div>
      </div>
    </DashboardStyles>
  );
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    companies: state.companies.companies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCompanies: companies => dispatch(actionCreators.setCompanies(companies))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
