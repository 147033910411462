import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
 // services
import { loginRequest } from "../../config/azureAuth.config";
import * as actionCreators from "../../actions/app.actions";
import * as api from "../../services/api/auth.service";
import { isAuthenticatedRole } from "../../services/roles.utils";
import { handleAzureLoginError } from "../../services/errors/login/AzureLoginError";
 // components
import Button from "../../components/button";
 // assets
import { BenefitLogo, MicrosoftIconSvg, EnvelopIconSvg } from "../../assets/Icons"
 // styles
import AzureLoginStyles from "./AzureLogin.styles";
 // constants
import { UserRolesKeysEnum } from "../../constants/roles.constants";

const AzureLogin = ({ setIsBenefitLogin, login }) => {
  const { instance } = useMsal();
  const history = useHistory();
  const azureLogin = async (token) => {
    const response = await api.azureLogin({token: token});
    
    if (response.hasError) {

      return toast.error(
        response.errorMessage ? response.errorMessage : "Login failed."
      );
    };

    if (isAuthenticatedRole(response.user.authenticatedRole, UserRolesKeysEnum.HR) && !response.user.isOnboardingDone) {
      history.push("/onboarding");
    };

    login(response.user);
    
    const metadata = {
      user_id: response.user.id,
      email: response.user.email,
      name: `${response.user.firstName} ${response.user.lastName}`,
      created_at: new Date(),
    };
    
    window.Intercom('trackEvent', 'user-login', metadata);
  };

/*
  const azureLoginWithSilentToken = async () => {
    console.log('silent')
    const activeAccount = instance.getActiveAccount();
    const tokenRequest = {
      scopes: ["User.Read.All"],
      account: activeAccount,
    };

  // Acquire the token
  instance.acquireTokenSilent(tokenRequest)
    .then(response => {      
      const accessToken = response.accessToken;
      azureLogin(accessToken);
    })
    .catch(error => {
      setIsLoading(false);
      return toast.error(error);
    });    
  };

  useEffect(() => {  
   isAuthenticated ? azureLoginWithSilentToken() : console.log("No active account.");
  }, [isAuthenticated]);
*/

  const handleLogin = async () => {  
    try {
      const response = await  instance.loginPopup({
          ...loginRequest,
          redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI
      });
      
      azureLogin(response.accessToken);

    } catch (error) {
      return handleAzureLoginError(error);      
    };   
  };
  
  return (
    <AzureLoginStyles>            
      <BenefitLogo color="#1568bf" />        
      <Button 
        onClick={() => setIsBenefitLogin(true)}
        type="button"
        padding="15px 0"
        margin="0"
        width="100%"
        height="65px"
        outline
        customClass="loginChange"
      >
        <EnvelopIconSvg/>
        Log in with email
      </Button>
      <Button 
        onClick={handleLogin}
        type="button"
        padding="15px 0"
        margin="0"
        width="100%"
        height="65px"
        outline
      >
        <MicrosoftIconSvg/>
        Log in with Microsoft
      </Button>
    </AzureLoginStyles>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    login: payload => dispatch(actionCreators.login(payload))
  };
};

AzureLogin.propTypes = {
  setIsBenefitLogin: PropTypes.func,
  login: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(AzureLogin);
