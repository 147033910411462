import styled from 'styled-components'

const EmployeesStyles = styled.section`
  .employeeContentContainer {
    width: calc(100% - 210px);
    margin-left: 210px;
    background: whitesmoke;
		min-height: 100vh;
		overflow-x: hidden;
  }

  .employeesContainer {
		padding: 0 30px 30px 30px;
		display: flex;
		position: relative;
  }

  .firstMenu {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    flex-wrap: wrap;
    background: #FFF;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: flex-start; 
    align-items: center; 
    width: 50%;
    min-width: 450px;
  }

  .searchIcon {
    background: #F7F9FC;
    padding: 12px 0 8px 10px;
    border-radius: 8px 0 0 8px;
    margin-right: -10px;
    z-index: 2;
  }

  .filterButton {                    
    font-weight: 500;                  
    line-height: 16px;                    
	}

  .buttonsContainer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .filtersContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    .closeFiltersButton {
       display: none;
      }
    .selectsContainer {
      display: flex;
      gap: 15px;
      > div {
        width: 202px;
      }    
    }
  }   

  .addContainer {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    color: #373737;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
	}

  .addSign {    
    margin-right: 8px;
    font-size: 28px;
    line-height: 28px;
    font-weight: 400;
	}
	
	#employeesList {
    transition: all 0.4s ease-in-out;
    opacity: 0;
		width: 100%;
    background-color: whitesmoke;
  }

  .firstMenuMobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
    background: #FFF;
    padding: 8px 16px;    

    .sortMobileButton {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .secondMenu {
    width: 100%;
    padding: 8px 20px;
    background: #FFF;

    .flexContainer {
      display: flex;
      align-items: center;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: #1D1D1D;
    }    
    
    .selected {
      width: 90px;
      color: ${props => props.selectedColor};
      margin: 0 11px;
    }      
  }
  
  .tableHead {
		padding: 14px 30px;
    display: flex;
    align-items: center;
		border-bottom: 1px solid #94ABD1;
		border-top: 1px solid #F0F0F0;
    background: #FFF;
  }

  .tableBodyContainer {
    background: #f7f7f7;
    padding: 30px;
    margin: 20px 0 0 0;
    border-radius: 4px;
  }

	#addEmployeeForm {
		position: absolute;
		width: calc(100% - 60px);
		transform: translateX(110%);
		transition: all 0.4s ease-in-out;
		opacity: 0;
	}

  .buttonText {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
	}
	
	.showAddEmployeeForm {
    transform: translateY(0) !important;
    opacity: 1 !important;
	}

	.hideEmployeeList {
		transform: translateX(-110%);
		opacity: 0;
    max-height: 1000px;
    overflow-y: auto;
	}

  .actionsDropdownWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 234px;
    right: 0;
    border-radius: 0px 4px 0px 0px;
    border: solid 1px transparent;
    z-index: 2;
    color: rgb(7, 6, 6);
    background-color: rgb(236, 236, 236);                        

    > span {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px 10px;
      cursor: pointer;
    }

    & span:first-child {
          display: none;
        }

    .buttonText {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .actionsSortWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 234px;
    left: 0;
    border-radius: 0px 4px 0px 0px;
    border: solid 1px transparent;
    z-index: 2;
    background-color: rgb(236, 236, 236);   
    
    .chevronContainer {
      width: 24px;
      height: 24px;
      background: #fff;
      border-radius: 50%;
      color: #373737;    
      margin-right: 10px;

      svg {
        margin: 3px 0 0 7px;
      }
	  }

    .sortButton {
      justify-content: flex-start;
    }

    .buttonText {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .appliedFiltersContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;    
    padding: 10px 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    background: #FFF;

    .appliedFilters {
      display: flex;
      align-items: center;
      gap: 10px;
      overflow-x: auto;
      max-width: calc(100% - 80px);
      flex-wrap: wrap;      

      .appliedFilterPill {
        display: flex;
        align-items: center;
        gap: 19px;
        background-color: #F0F4F9;
        color: #1D1D1D;
        padding: 6px 8px;
        border-radius: 23px;

          .removeFilter {
            cursor: pointer;
          }
      }
    }

    .clearButton {
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }  
    
    @keyframes showModal {
      from {
        transform: translateX(320px);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }
    
    .filtersContainer.mobile {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      width: 310px;
      padding: 90px 30px 30px 30px;
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 12%) 0px 1px 10px 0px, 
                  rgb(0 0 0 / 14%) 0px 4px 5px 0px;
      z-index: 10;      
      animation: showModal 0.4s ease-in-out;
      transition: all 0.35s ease-in-out;  
      transition-delay: 0.1s;
      > div {
        width: 100%;
      }
      .closeFiltersButton {
        display: unset;
        width: 40px;
        height: 40px;
        color: rgba(0, 0, 0, 0.54);
        border: none;
        outline: none;
        font-size: 20px;
        position: absolute;
        z-index: 2;
        right: 15px;
        top: 30px;
        font-weight: 500;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        &:hover {
          color: #1568bf;
          background: rgba(255, 255, 255, 0.8);
        }
      }
      .selectsContainer {
      flex-direction: column;
      width: 100%;
      > div {
        width: 100%;
      }      
     }      
    }    

    .paginationWrapper {
      display: flex;
      justify-content: center;
      background: #FFF;
      padding: 30px;                
    }

    @media screen and (max-width:1024px) {      
      .buttonsWrapper {
        min-width: 400px;
      }
    }

    @media screen and (max-width:960px) {      
      .employeesContainer {
		    padding: 0 15px 30px 15px;
      }

      .buttonsWrapper {
        min-width: 440px;
      }

      .addSign {
        margin: 0 4px;
      }

      .actionsDropdownWrapper {
        & span:first-child {
          display: flex;
        }
      }      
    }

    @media screen and (max-width:767px) {
      .employeeContentContainer {
        width: 100%;
        margin-left: 0;
      }

      .employeesContainer {
        padding: 0;
      }

      #employeesList {
        background: whitesmoke;
        padding: 0;
      }

      .firstMenu {
        padding: 8px 16px;
      }
      
      .buttonsWrapper {
        min-width: calc(100% - 60px);

        .filterButton {
          display: none;
        }
      }

      .firstMenuMobile {
        display: flex;
      }

      .secondMenu {
        margin: 11px 0;
      }

      .tableHead {
        display: none;
      }

      .paginationWrapper {
        margin-top: 10px;
        padding: 25px;
      }

      #addEmployeeForm {
	    	width: 100%;
	    }
    }
`

export default EmployeesStyles
