import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
//assets
import {
  TokenIcon,
  SyncIcon,
  InfoIconSvg,
  NotificationBellSvg,
  HamburgerIconSvg,
  BLogoSvg,
} from "../../assets/Icons";
//components
import LogoutDropdown from "./logoutDropdown/LogoutDropdown";
import SwitchPlatformsDropdown from "./switchPlatformsDropdown/SwitchPlatformsDropdown";
import BudgetBreakdownDropdown from "./budgetBreakdownDropdown/BudgetBreakdownDropdown";
import { CompanyThemeLogo } from "../";
// actions
import * as actionCreators from "../../actions/hr/employees.actions";
//services
import getInitals from "../../services/getInitals";
import { filterArrayByValueOfKey, isEmpty } from '../../services/general.utils';
// hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";
//styles
import StyledHeader from "./HeaderStyles";

const Header = ({
  headerTitle,
  svgIcon,
  isTokenVisable = false,
  openSyncModal,
  user,
  isSidebarMenuOpen,
  toggleSidebarMenu,
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isBreakdownOpen, setIsBreakdownOpen] = useState(false);

  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const handleClick = e => {
    const avatar = document.getElementById("avatar");
    if (avatar && !avatar.contains(e.target)) {
      setIsDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }); 

  return (
    <StyledHeader wrap={pathname.match("employees") ? "wrap-reverse" : "nowrap"}>
      <div className="titleContainer">
        {headerTitle ? (
          <h1 className="headerTitle">{svgIcon}{headerTitle}</h1>
        ) : (
          user?.authenticatedRole && 
            <CompanyThemeLogo 
              color="#1568bf" 
              theme={filterArrayByValueOfKey(user.themes, "mode", user.mode)[0]} 
              colorTheme="#1D1D1D"
            />
        )}
        {isTokenVisable && (
          <div className="mobileTokens">
            <TokenIcon outerFill="#4b4b4b" lineFill="#fff" />
            <p className="tokens">
              {user.remainingTokens >= 0 ? user.remainingTokens : 0}/{user.totalTokens}
            </p>
            <div className="tooltipContainer">
              <a data-tip data-for="sadFace">
                <InfoIconSvg />
              </a>
              <ReactTooltip
                id="sadFace"
                place="top"
                effect="solid"
                className="tooltip"
                backgroundColor="#1568bf"
                borderColor="#1568bf"
                arrowColor="#1568bf"
              >
                <span>Remaining tokens / Total number of tokens</span>
              </ReactTooltip>
            </div>
          </div>
        )}
      </div>

      <div className={pathname.match("employees") ? "tokenUserContainer mobile" : "tokenUserContainer"}>
        {isTokenVisable && (
          <>
            <div
              className="tokenContainer"
            >
              <div
                className="desktopTokens"
                onClick={e => {
                  e.stopPropagation();
                  setIsBreakdownOpen(!isBreakdownOpen);
                }}
              >
                <TokenIcon outerFill="#4b4b4b" lineFill="#fff" />
                <p className="tokens">
                  {user.remainingTokens >= 0 ? user.remainingTokens : 0}/{user.totalTokens}
                </p>
                <div className="tooltipContainer">
                  <a data-tip data-for="sadFace">
                    {" "}
                    <InfoIconSvg />{" "}
                  </a>
                  <ReactTooltip
                    id="sadFace"
                    place="top"
                    effect="solid"
                    className="tooltip"
                    backgroundColor="#1568bf"
                    borderColor="#1568bf"
                    arrowColor="#1568bf"
                  >
                    <span>Remaining tokens / Total number of tokens</span>
                  </ReactTooltip>
                </div>
              </div>
              {/**
               * Hide Submit button
               * This is temporary until the designer gives us a better solution       
               */}
              {/*
                <div className="sync" onClick={openSyncModal}>
                  <p className="syncText">Submit</p>
                  <SyncIcon />
                </div>
              */}
              <div className="sync" onClick={() => {}}>  
                <p className="syncText">Submit</p>
                <SyncIcon />
              </div>
            </div>
            {isBreakdownOpen &&
              <BudgetBreakdownDropdown
                user={user}
                budgetBreakdown={user.budgetBreakdown}
                setIsBreakdownOpen={setIsBreakdownOpen}
              />
            }
          </>
        )}         
        {pathname.match("employees") && width < 767 &&
          <div className="hamburgerIcon" onClick={() => toggleSidebarMenu(isSidebarMenuOpen)} >
            <HamburgerIconSvg/>
            <BLogoSvg color="#1568bf" width="26" height="24" opacity="1"/>
          </div>
        }        
        {!isEmpty(user?.roles) 
          ? <SwitchPlatformsDropdown user={user}/>
          : <div className="switchPlaceholder"></div>
        }        
        <div id="avatar" className="avatarStatusContainer">
          <div id="olvy-target" className="olvyTarget">
              <NotificationBellSvg/>
          </div>
          <div 
            className="avatarContainer"
            onClick={e => {
              e.stopPropagation();
              setIsDropDownOpen(!isDropDownOpen);
            }}
          >
            <p className="nameInitials">
              {getInitals(`${user.firstName} ${user.lastName}`)}
            </p>
          </div>
          <span className="loginStatus" />
          {isDropDownOpen && <LogoutDropdown user={user} />}
        </div>
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  headerTitle: PropTypes.string,
  isTokenVisable: PropTypes.bool,
  openSyncModal: PropTypes.func,
  user: PropTypes.shape(),
  isSidebarMenuOpen: PropTypes.bool,
  toggleSidebarMenu: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    isSidebarMenuOpen: state.employees.isSidebarMenuOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebarMenu: isSidebarOpen => dispatch(actionCreators.toggleSidebarMenu(isSidebarOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

