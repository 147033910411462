import React from 'react'
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components'

import {
  InfoIconSvg
} from '../../assets/Icons';

const Styles = styled.div`
  /* margin: 0 87px 0 0; */
  cursor: pointer;

  .tooltip {
    /* height: 107px; */
    background: #282a3b !important;
    border: 1px solid #282a3b !important;
    padding: 0;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: -0.22px;
    color: #ffffff;
    text-align: center;
    padding: 0 8px;
  }

  .tooltip:after {
    border-top: 8px solid #282a3b !important;
    margin-left: -3px !important;
  }

  .__react_component_tooltip.show {
    opacity: 0.9 !important;
    margin-top: -10px !important;
    margin-left: 0px !important;
    visibility: visible;
  }
`


const InfoPopUp = ({text, id}) => {
  return (
    <Styles>
      <a data-tip data-for={id}>
        {' '}
        <InfoIconSvg />{' '}
      </a>
      <ReactTooltip
        id={id}
        place="top"
        effect="solid"
        className="tooltip"
        backgroundColor="#1568bf"
        borderColor="#1568bf"
        arrowColor="#1568bf"
      >
        <span>{text}</span>
      </ReactTooltip>
    </Styles>
  )
}

export default InfoPopUp