import styled from 'styled-components'

const ActivityStyles = styled.section`
	.activityContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: #f7f7f7;
		min-height: 100vh;
		overflow-x: hidden;
	}

	#logsCard {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}

	.activityContent {
		padding: 0 30px 30px 30px;
	}

	.cardHeight {
		min-height: calc(100vh - 180px);
	}

	.header {
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #d8d8d8;
	}

	.filtersWrapper {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		border-bottom: 1px solid #d8d8d8;
		margin-bottom: 10px;
		gap: 50px;
		margin-bottom: 10px;
    padding-bottom: 20px;
	}

	.datePickerHolder {
		padding: 0 0 30px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.datePickerInput {
		height: 32px;
		width: 194px;
		outline: none;
		text-align: center;
		font-size: 16px;
		font-weight: 600;
		border-color: 1px solid #d8d8d8;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.57;
		letter-spacing: normal;
		text-align: center;
		color: #4b4b4b;
		cursor: pointer;
		position: relative;

		&:hover {
			border: 1px solid #1568bf;
			z-index: 2;
		}
	}

	input[type=date]::-webkit-clear-button {
		display: none;
    -webkit-appearance: none;
	}

	input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
	}

	input[type=date]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
		position: absolute;
		left: 5px;
		width: 5px;
		color: rgba(0, 0, 0, 0);
		opacity: 0;
		cursor: pointer;
	}

	.previousDayButton {
		position: relative;
		height: 32px;
		margin-right: -1px;
		outline: none;
		border: 1px solid #d8d8d8;
		width: 115px;
		text-align: center;
		font-size: 14px;
		font-weight: normal;;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.57;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
		cursor: pointer;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		background: #1568bf;
		
		&:hover {
			border: 1px solid #1568bf;
			z-index: 2;
		}
	}

	.nextDayButton {
		position: relative;
		height: 32px;
		margin-left: -1px;
		outline: none;
		border: 1px solid #d8d8d8;
		width: 115px;
		text-align: center;
		font-size: 14px;
		font-weight: normal;;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.57;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
		cursor: pointer;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		background: #1568bf;
		
		&:hover {
			border: 1px solid #1568bf;
			z-index: 2;
		}
		
		&:disabled {
			color: #4b4b4b;
			background: transparent;
			pointer-events: none;
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	.content {
		padding: 0 0 0 135px;
	}

	.listContainer {
		padding-left: 26px;
		min-height: 460px;
		border-left: 1px solid #d6d6d6;
	}

	.list {
		padding: 20px 0;
	}

	.item {
		margin-bottom: 20px;
		position: relative;
		display: flex;
		align-items: center;
	}

	.dotAndDateContainer {
		position: absolute;
		left: -79px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 60px;

		.time {
			font-size: 12px;
    	color: #a8a8a8;
		}
		
		.outerDot {
			width: 15px;
			height: 15px;
			border-radius: 15px;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.innerDot {
			width: 7px;
			height: 7px;
			border-radius: 7px;
			background: #1568bf;
		}
	}

	.logAndAvatar {
		display: flex;
		align-items: center;
		
		.log {
			margin-left: 5px;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.57;
			letter-spacing: normal;
			color: #4b4b4b;
		}
	}
	
	.text {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.57;
		letter-spacing: normal;
		text-align: center;
		color: #4b4b4b;
		margin-top:30px;
	}
`

export default ActivityStyles
