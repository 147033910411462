import React from 'react'
import PropTypes from 'prop-types'

import FormModalStyles from './FormModalStyles'

const FormModal = ({ closeModal, children }) => (
	<FormModalStyles onClick={closeModal}>
		<div className="modal" id="modal" onClick={e => e.stopPropagation()}>
			<div className="closeButton" id="closeButton" onClick={closeModal}>
				X
			</div>
			{children}
		</div>
	</FormModalStyles>
)

FormModal.propTypes = {
	closeModal: PropTypes.func,
	children: PropTypes.node
}

export default FormModal
