import React from 'react'
import PropTypes from 'prop-types'

import ItemStyles from './ItemStyles'

const Item = ({ name, removeItem, editItem }) => (
	<ItemStyles onClick={() => editItem()}>
		<p className="tagName">{name}</p>
		<button
			type="button"
			className="removeButton"
			onClick={(e) => {
				e.stopPropagation()
				removeItem()
			}}
		>
			X
		</button>
	</ItemStyles>
)

Item.propTypes = {
	name: PropTypes.string,
	removeItem: PropTypes.func,
	editItem: PropTypes.func
}

export default Item
