import styled from "styled-components";

const EmployeeBenefitsStyles = styled.div`
  background: whitesmoke;
  min-height: 100vh;

  .container {
    padding: 0 30px 30px 30px;
    width: 100%;

    .benefitsAndFilterContainer {
      display: flex;
    }
  }

  #benefitGroupList {
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  .benefitList {
    display: flex;
    max-width: 1440px;
    flex-wrap: wrap;
    margin: 30px 0 30px 0;
  }

  .noItemsContainer {
    height: calc(100vh - 250px);
    display: flex;
    align-items: center;
    justify-content: center;

    .noItemsTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 26px;
      text-align: center;
      color: #4b4b4b;
    }
  }

  @media only screen and (max-width: 1099px) {
    .showFilters {
      transition-delay: 0.1s;
      transform: translateX(0) !important;
    }

    .showWrapper {
      visibility: visible !important;
    }
  }

  @media only screen and (max-width: 780px) {
    .benefitList {
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 767px) {
    .container {
      padding: 0 0 30px 0;
    }

    .timerAndFilters {
      .buttonsContainer {
        width: 100%;
        justify-content: space-between;
      }
      /* .mobileFilterButton {
        display: unset;
        font-size: 12px !important;
        width: 80px !important;
      } */
    }
    .benefitList {
      margin: 30px 0 30px 0;
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 500px) {
    .lastMobile {
      width: 100%;
    }
  }
`;

export default EmployeeBenefitsStyles;
