import styled from 'styled-components'

const EmployeeListItemStyles = styled.div`
  border-bottom: ${({border}) => border || "1px solid #F0F0F0"};
  padding: 12px 20px;
  background: ${({background}) => background || "white"};

  .listItem {
    width: 100%;
    display: flex;
    -moz-box-align: center;
    align-items: center;  
  }
  
  .checkboxCol {
    margin-right: 24px;
    width: 18px;
  }

  .list {
    display: flex;
    align-items: center;
    width: 100%;  
    cursor: pointer;
  }

  .nameCol {
    display: flex;
    align-items: center;
    width: 30%;
    padding: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    text-transform: capitalize;
    position: relative;
    
    .employeeAvatar {
      margin-left: 10px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 12px;
      background: #1568bf;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
    }

    .userDisabledIconWrapper {
      position: absolute;
      top: 20px;
      left: 58px;
    }
  }

  .tier {
    width: 15%;
    padding: 16px 0;
    margin-left: 2%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
  }

  .tokens {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 15%;
    padding: 16px 0 16px 16px;
    margin-left: 1%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
  }

  .budgetPercentage {
    width: 15%;
    padding: 16px 0;
    margin-left: 1%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
  }

  .email {
    width: 22%;
    padding: 16px;
    margin-left: 1%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actionsCol {
    width: 4%;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    background: none;
    border: medium none;
    outline: currentcolor none medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(143, 143, 143);
    cursor: pointer;
    position: relative;
    margin: 0px;
    padding: 0px;

    .tooltip {
      width: 180px;
      /* height: 107px; */
      background: #f7f7f7;
      border: 1px solid #1568bf !important;
      padding: 0;
      border-radius: 4px;
      padding: 0;
    }
  }

  .tooltipLink {
    text-decoration: none;
    width: 100%;
    height: 34px;
  }

  .tooltipContentWrapper {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .button {
    width: 100%;
    height: 34px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    text-transform: capitalize;

    &:hover {
      background: #1568bf;
      color: #fff;
    }
  }

  .list-disabled-tooltip {
    color: black !important;
    text-align: center;
  }

  .__react_component_tooltip {
    background: #f7f7f7 !important;
    opacity: 1 !important;
    padding: 0;
    width: 180px;
    margin-left: -40px;
    border-radius: 4px;
    border: 1px solid rgb(21, 104, 191) !important;
  }

  .__react_component_tooltip.show {
    opacity: 1;
    visibility: visible;
  }

  .__react_component_tooltip.type-dark.border {
    border: 1px none blue;
  }

  .__react_component_tooltip.place-bottom {
    margin-left: -45px;
  }

  .__react_component_tooltip.place-bottom:after {
    top: -7px !important;
    left: 75%;
  }

  .__react_component_tooltip.place-left::after {
    border-left: 8px solid #f7f7f7 !important;
  }

  .__react_component_tooltip.place-right::after {
    border-right: 8px solid #f7f7f7 !important;
  }

  .__react_component_tooltip.place-top::after {
    border-top: 8px solid #f7f7f7 !important;
  }

  .__react_component_tooltip.place-bottom::after {
    border-bottom: 8px solid #f7f7f7 !important;
  }

  .__react_component_tooltip.place-bottom:before {
    border-left: 9px solid transparent;
    border-right: 10px solid transparent;
    left: 75%;
  }

  .__react_component_tooltip.type-dark.border.place-bottom:before {
    border-bottom: 8px solid #1568bf !important;
  }

  .mobileListContainer {
    display: none;
  }

  @media screen and (max-width:1280px) {
    .nameCol {
      width: 48%;    
    }

    .tier {
      width: 10%;
    }

    .tokens {
      width: 25%;    
    }

    .budgetPercentage {
      width: 10%;
    }

    .email{
      display: none;
    }

    .actionsCol {
      width: 6%;  
    }
  }

  @media screen and (max-width:960px) {
    .checkboxCol {
      margin-right: 0;
    }
    
    .nameCol {
      width: 55%;    
    }

    .tier {
      width: 15%;
    }

    .tokens {
      width: 30%;    
    }

    .budgetPercentage {
      display: none;
    }    

    .__react_component_tooltip.place-bottom {
      margin-left: -50px;
    }
  }

  @media screen and (max-width:767px) {
    margin-top: 8px;    
    padding: 12px 16px;

    .__react_component_tooltip.place-bottom {
      margin-left: -60px;
    }
  }

  @media screen and (max-width: 500px) {
    .nameCol {
      width: 90%;  
      padding: 0 8px;

      div svg:first-child {
        display: none;
      }

      .userDisabledIconWrapper {
        top: 7px;
        left: 35px;
      }
    }

    .tier {
      display: none;
    }

    .tokens {
      display: none;
    }

    .mobileListContainer {
      display: flex;
      align-items: center;
      padding: 12px 0 0 0;

      .mobileItem {
        display: flex;
        flex-direction: column;

        .mobileTitle {
          font-weight: 500;
          font-size: 10px;
          line-height: 20px;
          color: #1D1D1D;
        }

        .mobileToken {
          display: flex;
          align-items: center;
        }

        .mobileData {
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          color: #777777;
        }
      }

      .tierMobile {
        width: 40%;
      }

      .tokensMobile {
        width: 43%;        
      }

      .budgetPercentageMobile {
        width: 16%;
        margin-left: -1%;
      }
    }

    .__react_component_tooltip.place-bottom {
      margin-left: -45px;
    }
  }  
`

export default EmployeeListItemStyles;
