import React from "react";
import InfoPopUp from "../../../../../components/InfoPopUp/InfoPopUp";

export const tableHeaderListHREmployees = [
  [
    {
      id: 1,
      field: "name",
      headName: "Name",
      isSortedUp: null,
      width: "30%",
      justifyContent: "flex-start",
      margin: "",
      isSortable: true,
      orderPriority: 0,
      margin: '0 0.5% 0 75px',
    },
    {
      id: 2,
      field: "tier",
      headName: "Tier",
      isSortedUp: null,
      width: "15%",    
      justifyContent: 'flex-start',
      margin: '0 0 0 1%',
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 3,
      field: "tokens",
      headName: "Budget",
      isSortedUp: null,
      width: "15%",
      justifyContent: 'flex-start',
      margin: '0 0 0 4%',
      isSortable: false,
      orderPriority: 0,
      Component: (
        <InfoPopUp text="Remaining tokens/Total number of tokens" id="popUp" />
      )
    },
    {
      id: 4,
      field: "used_budget_percentage",
      headName: "Spent(%)",
      isSortedUp: null,
      width: "15%",
      justifyContent: 'flex-start',
      margin: '0 0 0 1%',
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 5,
      field: "email",
      headName: "Email",
      isSortedUp: null,
      width: "27%",
      justifyContent: "flex-start",
      margin: '0 0 0 3%',
      isSortable: true,
      orderPriority: 0
    }
  ],
  [  
    {
      id: 1,
      field: "name",
      headName: "Name",
      isSortedUp: null,
      width: "48%",
      justifyContent: "flex-start",
      margin: "",
      isSortable: true,
      orderPriority: 0,
      margin: '0 0.5% 0 75px',
    },
    {
      id: 2,
      field: "tier",
      headName: "Tier",
      isSortedUp: null,
      width: "10%",    
      justifyContent: 'flex-start',
      margin: '0 0 0 1%',
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 3,
      field: "tokens",
      headName: "Budget",
      isSortedUp: null,
      width: "25%",
      justifyContent: 'flex-start',
      margin: '0 0 0 4%',
      isSortable: false,
      orderPriority: 0,
      Component: (
        <InfoPopUp text="Remaining tokens/Total number of tokens" id="popUp" />
      )
    },
    {
      id: 4,
      field: "used_budget_percentage",
      headName: "Spent(%)",
      isSortedUp: null,
      width: "17%",
      justifyContent: 'flex-start',
      margin: '0 0 0 1%',
      isSortable: true,
      orderPriority: 0
    }  
  ],
  [
  
    {
      id: 1,
      field: "name",
      headName: "Name",
      isSortedUp: null,
      width: "48%",
      justifyContent: "flex-start",
      margin: "",
      isSortable: true,
      orderPriority: 0,
      margin: '0 0.5% 0 51px',
    },
    {
      id: 2,
      field: "tier",
      headName: "Tier",
      isSortedUp: null,
      width: "15%",    
      justifyContent: 'flex-start',
      margin: '0 0 0 1%',
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 3,
      field: "tokens",
      headName: "Budget",
      isSortedUp: null,
      width: "30%",
      justifyContent: 'flex-start',
      margin: '0 0 0 4%',
      isSortable: false,
      orderPriority: 0,
      Component: (
        <InfoPopUp text="Remaining tokens/Total number of tokens" id="popUp" />
      )
    }
  ],
  [
    
  ]
];

