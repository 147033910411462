import React, { useMemo, useRef } from "react";
// Components
import BudgetBreakdownDropdownStyles from "./BudgetBreakdownDropdown.styles";
import { TokenIcon } from "../../../assets/Icons";
// Hooks
import useClickOutside from "../../../hooks/useClickOutside";


const BudgetBreakdownDropdown = ({
  user,
  budgetBreakdown,
  setIsBreakdownOpen
}) => {
  const budgetBreakdownTooltip = useRef(null);
  useClickOutside([budgetBreakdownTooltip], () => setIsBreakdownOpen(false));

  const totalSpent = useMemo(() => user.availableTokens - user.remainingTokens, [user.remainingTokens])

  return (
    <BudgetBreakdownDropdownStyles>
      <div id="tooltip" className="budgetBreakdown">
        <div className="budgetBreakdownHeader">
          <div className="budgetLabel">
            Budget
          </div>
          <div className="budget">
            {user.remainingTokens}/{user.availableTokens} <TokenIcon />
          </div>
        </div>
        <div className="budgetBreakdownContent">
          <div className="contentWrapper">
            <div className="breakdownLabel">
              Subscribed monthly
            </div>
            <div className="breakdownContent">
              <span className="amount">{budgetBreakdown.monthlySpent}</span> <TokenIcon />
            </div>
          </div>
          <div className="contentWrapper">
            <div className="breakdownLabel">
              Subscribed one-time
            </div>
            <div className="breakdownContent">
              <span className="amount">{budgetBreakdown.oneTimeSpent}</span> <TokenIcon />
            </div>
          </div>
          <div className="contentWrapper">
            <div className="breakdownLabel">
              Subscribed vouchers
            </div>
            <div className="breakdownContent">
              <span className="amount">{budgetBreakdown.vouchersSpent}</span> <TokenIcon />
            </div>
          </div>
          <div className="contentWrapper">
            <div className="breakdownLabel">
              Instalments
            </div>
            <div className="breakdownContent">
              <span className="amount">{budgetBreakdown.instalmentsSpent}</span><TokenIcon />
            </div>
          </div>
          <div className="contentWrapper">
            <div className="breakdownLabel">
              MCP
            </div>
            <div className="breakdownContent">
              <span className="amount">{budgetBreakdown.mcpSpent}</span><TokenIcon />
            </div>
          </div>
          <hr className="divider" />
          <div className="totalSpentWrapper">
            <div className="totalSpentContent">
              <span className="totalSpentLabel">Total Spent</span> <span className="amount">{totalSpent}</span><TokenIcon />
            </div>
          </div>
        </div>
      </div>
    </BudgetBreakdownDropdownStyles>
  );
};

export default BudgetBreakdownDropdown;
