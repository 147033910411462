import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SearchBar from 'material-ui-search-bar';
import SearchIcon from '@material-ui/icons/Search';

import SearchInputStyles from './SearchInputStyles';

const SHOW_SEARCH_ICON = false;
const SEARCH_TIMER_IN_MILISEC = 500;
let searchTimer = null;

const SearchInput = ({
  fetchData,
  setSearch,
  search,
  setPagginationPage,
  customClass = '',
  style = { maxWidth: 400 },
  useTimeout = true,
  searchWrapperWidth = 'initial',
  placeholder = 'Search',
  placeholderColor = ""
}) => {
  const searchInputRef = useRef(null);

  const onChangeSearch = searchText => {
    setSearch(searchText);

    if (useTimeout) {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
      searchTimer = setTimeout(() => {
        setPagginationPage(1);
        fetchData(searchText, 1);
      }, SEARCH_TIMER_IN_MILISEC);
    }
  };

  return (
    <SearchInputStyles searchWrapperWidth={searchWrapperWidth} placeholderColor={placeholderColor}>
      <SearchBar
        ref={searchInputRef}
        className={customClass}
        closeIcon={SHOW_SEARCH_ICON ? <SearchIcon /> : <></>}
        searchIcon={SHOW_SEARCH_ICON ? <SearchIcon /> : <></>}
        onCancelSearch={SHOW_SEARCH_ICON ? () => fetchData() : undefined}
        onRequestSearch={() => fetchData()}
        value={search}
        onChange={onChangeSearch}
        style={style}
        placeholder={placeholder}
      />
    </SearchInputStyles>
  );
};

SearchInput.propTypes = {
  fetchData: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.string,
  setPagginationPage: PropTypes.func
};

export default SearchInput;
