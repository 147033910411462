import React, { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

// Components
import { DotsIcon, UserDisabledIconSvg, ChevronIconDownSvg, TokenIcon } from '../../assets/Icons';
import EmployeeListItemStyles from './EmployeeListItemStyles';
import EmployeeListItemExpandedStyles from './EmployeeListItemExpandedStyles';
import EmployeeActiveBenefits from './employeeActiveBenefits';
import CheckboxInput from '../checkboxInput';
// Services
import getInitals from '../../services/getInitals';
import * as usersService from "../../services/api/admin/users.service";
import * as employeesService from "../../services/api/hr/employees.service";
import { isEmpty } from '../../services/general.utils';
import { formatDateForPlaceholder } from '../../services/date.utils';
import { override } from "../../services/loadingOverride.styles";

// Entity
import { HrEmployeeForm } from "../../models/domain";

const EmployeeListItem = ({
  fullName,
  isRowDisabled,
  isFirstLetter = false,
  tier,
  tokens,
  remainingTokens,
  userEmail,
  budgetSpentPercentage,
  isActionsEnabled = true,
  userId,
  isEditActionVisable = true,
  employeeId,
  isResendVerificationEnabled = true,
  shouldShowDeletePopup = true,
  isDeleteActionVisable = true,
  openDisableUserPopup = () => { },
  openAddBonusModal = () => { },
  fetchData = () => { },
  gender,
  cities,
  relationship,
  bornDate,
  firstDayAtWork,
  team,
  contact,
  bonusAmount,
  setSelectedNumber,
  checkedMap,
  setCheckedMap,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [singleEmployee, setSingleEmployee] = useState(new HrEmployeeForm());
  const init = checkedMap.has(userId) ? checkedMap.get(userId) : false;
  const [isChecked, setIsChecked] = useState(init);

  const myTooltip = useRef();
  const history = useHistory();

  /**
   * Sends request to get user active subscribed benefits.
   * @param {Number} employeeId 
   */
  const fetchEmployee = async (employeeId) => {
    const response = await employeesService.getChosenCompanyEmployee(employeeId);

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    setSingleEmployee(response);
    setIsLoading(false);     
  };

  const onClickDots = () => {
    setIsActive(true);
    ReactTooltip.rebuild();
  };

  const handleBlur = () => {
    setIsActive(false);
    ReactTooltip.rebuild();
  };

  const handleExpand = (event) => {
    event.preventDefault();
    event.stopPropagation();
    fetchEmployee(employeeId);
    setIsOpen(!isOpen);
  }

  const resendVerificationLink = async (e, userId) => {
    e.stopPropagation();
    const response = await usersService.resendVerificationLink(
      userId
    );
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Verification link was not sent, please contact support."
      );
    }

    toast.success(response.data.message);
  };

  const handleDisableUserPopupOpen = (e, userId) => {
    e.stopPropagation();
    openDisableUserPopup(userId);
  }

  /**
   * Handles user enabling.
   * Sends request to update user enabled status.
   * Refetches data.
   * @param {Event} e 
   */
  const handleUserEnable = async (e, userId) => {
    e.stopPropagation();

    const response = await usersService.enableUser(userId);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Enabling user failed."
      );
    }

    toast.success(response.data.message);

    fetchData();
  }

  /**
  * Handles the behavior when the checkedMap is changed
  * If user in checkedMap is true, set isChecked to true, otherwise false.
  * Calculate and set total number of selected users
  */
  useEffect(() => {    
    let total = 0;
    for (const x of checkedMap.values()) {
      x && total ++;
    };

    setSelectedNumber(total);
    checkedMap.has(userId) && setIsChecked(checkedMap.get(userId));    
  }, [checkedMap]);

  const handleCheckedOnChange = () => {
    const checked = new Map(checkedMap);
    checked.set(userId, !isChecked);

    setCheckedMap(checked);
    setIsChecked(!isChecked);
  };

  return (
    <>
      <EmployeeListItemStyles background={isChecked && "#F5FAFF"} border={isOpen && "1px solid transparent"}>
        <div className='listItem'>
          <div className="checkboxCol">
            {!isRowDisabled && (
              <CheckboxInput 
                onChange={handleCheckedOnChange} 
                isChecked = {isChecked} 
                name={userId?.toString()}
                width="18px"
                height="18px"
              />
            )}
          </div>
          <div className="list" onClick={e => handleExpand(e)} >
            <div className="nameCol">
              <ChevronIconDownSvg isOpen={isOpen} fill={isOpen ? "#668CCC" : "#DBDBDB"} />
              <div className="employeeAvatar">
                {getInitals(fullName, isFirstLetter)}
              </div>
              <span>{fullName !== "" ? fullName : "Unknown"}</span>
              {isRowDisabled && (
                <>
                  <span
                    data-tip={`${userId}|${isRowDisabled}`}
                    data-for={`userDisabled-${userId}`}
                    className="userDisabledIconWrapper"
                  >
                    <UserDisabledIconSvg />
                  </span>
                  <ReactTooltip
                    id={`userDisabled-${userId}`}
                    place="top"
                    effect="solid"
                    className="email-tooltip list-disabled-tooltip"
                    backgroundColor="#000000"
                    borderColor="#000000"
                    arrowColor="#000000"
                  >
                    <span>User is disabled</span>
                  </ReactTooltip>
                </>
              )}
            </div>
            <div className="tier">
              {tier}
            </div>
            <div className="tokens">
              <TokenIcon/>
              {remainingTokens}/{tokens}
            </div>
            <div className="budgetPercentage">
              {budgetSpentPercentage}
            </div>
            <div className="email">
              {userEmail}
            </div>
            {isActionsEnabled && (
              <>
                <button
                  type="button"
                  className="actionsCol"
                  data-tip={`${userId}|${isRowDisabled}|${employeeId}`}
                  data-event={isActive ? "focus" : "click"}
                  data-event-off={!isActive ? "focusout" : ""}
                  onClick={e => {
                    e.stopPropagation();
                    onClickDots();
                  }}
                  onBlur={() => handleBlur()}
                >
                  <DotsIcon transform="rotate(90deg)" transformOrigin="90% 90%"/>
                </button>
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  // eslint-disable-next-line
                  ref={ref => (myTooltip.current = ref)}
                  className="tooltip"
                  getContent={dataTip => {
                    if (!dataTip) {
                      return "";
                    }
                    const [
                      userId,
                      isDisabledParam,
                      employeeId
                    ] = dataTip.split("|");

                    return (
                      <div className="tooltipContentWrapper">
                        {isEditActionVisable && (
                          <button
                            type="button"
                            className="button"
                            onClick={e => {
                              e.stopPropagation();
                              history.push(`/employees/edit/${employeeId}`);
                            }}
                          >
                            Edit
                          </button>
                        )}
                        {(
                          <button
                            type="button"
                            className="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              openAddBonusModal({ title: "Add Bonus", selectName: 'employee' }, Number(employeeId));
                            }}
                          >
                            Add bonus budget
                          </button>
                        )}
                        {isDisabledParam === 'true' && (
                          <button
                            type="button"
                            className="button"
                            onClick={async (e) => handleUserEnable(e, userId)}
                          >
                            Enable user
                          </button>
                        )}
                        {isResendVerificationEnabled && (
                          <button
                            type="button"
                            className="button"
                            onClick={async (e) =>  resendVerificationLink(e, userId)}
                          >
                            Resend verification link
                          </button>
                        )}
                        {isDeleteActionVisable && shouldShowDeletePopup && isDisabledParam === 'false' && (
                          <button
                            type="button"
                            className="button"
                            onClick={async (e) => handleDisableUserPopupOpen(e, userId)}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    );
                  }}
                  border
                  clickable
                />
              </>
            )}          
          </div>
        </div>
        <div className="mobileListContainer" onClick={e => handleExpand(e)}>
          <div className="mobileItem tierMobile">
            <span className='mobileTitle'>Tier</span>
            <span className='mobileData'>{tier}</span>
          </div>
          <div className="mobileItem tokensMobile">
            <span className='mobileTitle'>Budget</span>
            <span className='mobileData mobileToken'>
            <TokenIcon/>
            {remainingTokens}/{tokens}
            </span>
          </div>
          <div className="mobileItem budgetPercentageMobile">
            <span className='mobileTitle'>Spent %</span>
            <span className='mobileData'>{budgetSpentPercentage}</span>
          </div>
        </div>
      </EmployeeListItemStyles>

      <EmployeeListItemExpandedStyles onClick={e => handleExpand(e)} backgroundOpen={isChecked && "#F5FAFF"} >
        {isOpen && (
          <div className="listItemExpanded open">
            <div className="employeeDetails">
              <div className="genderAndLocation">
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Email
                  </span>
                  <span>
                    {!isEmpty(userEmail) ? userEmail : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer gender">
                  <span className="expandedListItemLabel">
                    Gender
                  </span>
                  <span className="genderValue">
                    {!isEmpty(gender) ? gender : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer locations">
                  <span className="expandedListItemLabel">
                    Locations
                  </span>
                  <span>
                    {!isEmpty(cities) ? cities.map(l => l.name).join(", ") : '-'}
                  </span>
                </div>                                
              </div>              
              <div className="birthDayAndEnrolldateWrapper">                
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Birth date
                  </span>
                  <span>
                    {!isEmpty(bornDate) ? formatDateForPlaceholder(bornDate) : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Enroll date
                  </span>
                  <span>
                    {!isEmpty(firstDayAtWork) ? formatDateForPlaceholder(firstDayAtWork) : '-'}
                  </span>
                </div>                
              </div>
              <div className="teamAndPositionWrapper">                
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Team
                  </span>
                  <span>
                    {!isEmpty(team) ? team : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Relationship
                  </span>
                  <span className="genderValue">
                    {!isEmpty(relationship) ? relationship : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Enroll date
                  </span>
                  <span>
                    {!isEmpty(firstDayAtWork) ? formatDateForPlaceholder(firstDayAtWork) : '-'}
                  </span>
                </div>                
              </div>
              <div className="bonusesWrapper">                
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Bonuses
                  </span>
                  <span>
                    {!isEmpty(bonusAmount) ? bonusAmount : '-'}
                  </span>
                </div>                
              </div>                            
              <div className="contactWrapper">                
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Spent(%)
                  </span>
                  <span>
                    {!isEmpty(budgetSpentPercentage) ? budgetSpentPercentage : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Bonuses
                  </span>
                  <span>
                    {!isEmpty(bonusAmount) ? bonusAmount : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Contact
                  </span>
                  <span>
                    {!isEmpty(contact) ? contact : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Birth date
                  </span>
                  <span>
                    {!isEmpty(bornDate) ? formatDateForPlaceholder(bornDate) : '-'}
                  </span>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div
                style={{
                  height: "calc(100vh - 180px)",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <BeatLoader
                  css={override}
                  size={25}
                  color="#123abc"
                  loading={isLoading}
                />
              </div>
            ) : (
              <EmployeeActiveBenefits singleEmployee={singleEmployee} backgroundOpen={isChecked && "#F5FAFF"}/>
            )}                       
          </div>
        )}
      </EmployeeListItemExpandedStyles>    
    </>
  )
}

EmployeeListItem.propTypes = {
  fullName: PropTypes.string,
  isRowDisabled: PropTypes.bool,
  isFirstLetter: PropTypes.bool,
  tier: PropTypes.string,
  tokens: PropTypes.number,
  remainingTokens: PropTypes.number,
  userEmail: PropTypes.string,
  budgetSpentPercentage: PropTypes.string,
  isActionsEnabled: PropTypes.bool,
  userId: PropTypes.number,
  isEditActionVisable: PropTypes.bool,
  employeeId: PropTypes.number,
  isResendVerificationEnabled: PropTypes.bool,
  shouldShowDeletePopup: PropTypes.bool,
  isDeleteActionVisable: PropTypes.bool,
  openDisableUserPopup: PropTypes.func,
  openAddBonusModal: PropTypes.func,
  fetchData: PropTypes.func,
  gender: PropTypes.string,
  cities: PropTypes.array,
  relationship: PropTypes.string,
  bornDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  firstDayAtWork: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  team: PropTypes.string,
  contact: PropTypes.string,
  bonusAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  checkedMapList: PropTypes.shape({}),
  setSelectedNumber: PropTypes.func,
};

export default EmployeeListItem;
