import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef
} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import ReactTooltip from "react-tooltip";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import SearchIcon from '@material-ui/icons/Search';
// custom components
import {
  Header,
  Card,
  Table,
  TableHead,
  TableRow,
  Button,
  Modal,
  SearchInput,
  openModalAnim,
  closeModalAnim,
  MultiselectInput,
  DisableUserModal,
  DisableUsersModal,
  CheckboxInput,
  EmployeeListItem
} from "../../../../components";
import AddEmployee from "./AddEmployee";
import AddEmployeesWithCSV from "./AddEmployeeWthCSV";
import UpdateEmployeesWithCSV from "./UpdateEmployeeWithCSV";
import AddEmployeeWithCSVError from "./errorPopup/AddEmployeeWithCSVError";
import AddBonus from './AddBonus';
// style component
import EmployeesStyles from "./EmployeesStyles";
import { override } from "../../../../services/loadingOverride.styles";
// assets
import { EmployeesIcon, FilterIconMobileSvg, FilterIconSvg, SortIconSvg, TrashIconSvg, AddIconSvg } from '../../../../assets/Icons';
// config for table head
import { tableHeaderReducer } from "./builderResponsive";
import { getTableHeadHREmployees } from "../../../../services/getTableHead.util";
// services
import { getOrderColumns } from "../../../../services/getOrderColumns";
import * as employeesService from "../../../../services/api/hr/employees.service";
import * as usersService from '../../../../services/api/admin/users.service';
import * as actionCreators from "../../../../actions/hr/employees.actions";
// context
import { HrEmployeesContext } from "../../../../modules/hr/hrEmployees/context/hrEmployees.context";
import { UserContext } from "../../../../modules/user/context/user.context";
// Hooks
import { useDisableBodyScroll } from "../../../../hooks/useDisableBodyScroll";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { isEmpty } from "../../../../services/general.utils";
import ChevronIconDownSvg from "../../../../assets/Icons/ChevronIconDownSvg";
import useClickOutside from "../../../../hooks/useClickOutside";
// config
const LIMIT = 35;
let firstSetTimeOut = null;
let secondSetTimeOut = null;

const Employees = ({
  setHrEmployees,
  setHrEmployeePage,
  setHrEmployeeSearch,
  employees,
  count,
  page,
  search
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isAddEmployeeOpen, setIsAddEmployeeOpen] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [errors, setErrors] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [chosenTiers, setChosenTiers] = useState([]);
  const [chosenTierIds, setChosenTierIds] = useState([]);
  const [chosenBudgetRanges, setChosenBudgetRanges] = useState([]);
  const [disableUserPopup, setDisableUserPopup] = useState(false);
  const [disableUsersPopup, setDisableUsersPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [modalType, setModalType] = useState(undefined);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileSortWrapperOpen, setIsMobileSortWrapperOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedMap, setCheckedMap] = useState(new Map());

  
  const { width } = useWindowDimensions();
  const [tableHeaders, dispatch] = useReducer(tableHeaderReducer, width, getTableHeadHREmployees);
    
  const history = useHistory();
  
  const tooltip = useRef(null);
  const tooltipActionsSortWrapper = useRef(null);
  const isMounted = useRef(true);
  const areFiltersLoaded = useRef(false);
  
  useDisableBodyScroll(isModalOpen);

  const budgetRangesOptions = [
    {
      id: 1,
      name: '0% - 25%',
      from: 0,
      to: 25
    },
    {
      id: 2,
      name: '25% - 50%',
      from: 25,
      to: 50
    },
    {
      id: 3,
      name: '50% - 75%',
      from: 50,
      to: 75
    },
    {
      id: 4,
      name: '75% - 100%',
      from: 75,
      to: 100
    }
  ];

  // const { hrEmployeeState, hrEmployeesActions } = useContext(
  //   HrEmployeesContext
  // );

  // const { user } = useContext(UserContext);

  const fetchEmployees = async (searchText = search, tierIds = chosenTierIds) => {
    const response = await employeesService.getCompanyEmployees({
      limit: LIMIT,
      page,
      searchText,
      orderColumns: getOrderColumns(tableHeaders),
      tierIds,
      chosenBudgetRanges: chosenBudgetRanges
    });

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    if (isMounted.current && response) {
      firstSetTimeOut = setTimeout(() => {
        setHrEmployees(response.employees, response.count);
        setIsLoading(false);
        secondSetTimeOut = setTimeout(() => {
          const employeesList = document.getElementById("employeesList");
          if (isMounted.current && employeesList) {
            employeesList.style.opacity = 1;
          }
        }, 100);
      }, 100);
    }
  };

  /**
   * Fetches tiers for multiselect dropdown.
   * Sets to state.
   */
  const fetchTiers = async () => {
    const response = await usersService.getTierOptions();
    if (response.hasError) {
      return toast.error(response.errorMessage ? response.errorMessage : "Fetching tiers failed")
    }

    setTiers(response);

    areFiltersLoaded.current = true;
  }

  useEffect(() => {
    fetchEmployees();
    fetchTiers();
    return () => () => {
      isMounted.current = false;
      clearTimeout(firstSetTimeOut);
      clearTimeout(secondSetTimeOut);
    };
  }, [page, tableHeaders]);

  /**
   * Opens disable user popup.
   * Sets selected user id to state.
   * @param {number} userId 
   */
  const openDisableUserPopup = (userId) => {
    setSelectedUserId(userId)
    setDisableUserPopup(true);

    setTimeout(() => {
      openModalAnim();
    }, 500);
  }

  /**
 * Opens disable users popup.
 * Sets selected users ids to state.
 */
    const openDisableUsersPopup = () => {
    if (selectedNumber < 1) {
      return toast.error('Please select users.');
    };

    setDisableUsersPopup(true);

    setTimeout(() => {
      openModalAnim();
    }, 500);
  }

  useEffect(() => {
    if (areFiltersLoaded.current === true)
      fetchEmployees();
  }, [chosenTierIds, chosenBudgetRanges]);

  // Add CSV Modal
  const openModal = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  const closeModal = () => {
    closeModalAnim();
    setTimeout(() => {
      setIsModalOpen(false);
    }, 350);
  };

  // Update CSV Modal
  const openUpdateModal = () => {
    setIsUpdateModalOpen(true);
    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  const closeUpdateModal = () => {
    closeModalAnim();
    setTimeout(() => {
      setIsUpdateModalOpen(false);
    }, 350);
  };

  // Add Bonus Modal
  const openAddBonusModal = (type, employeeId = null) => {
    setSelectedEmployeeId(employeeId);
    setModalType(type);

    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  const closeAddBonusModal = () => {
    closeModalAnim();
    setTimeout(() => {
      setModalType(undefined);
    }, 350);
  };


  // Add Employee
  const openAddEmployee = () => {
    setIsAddEmployeeOpen(true);
    setTimeout(() => {
      const addEmployeeForm = document.getElementById("addEmployeeForm");
      const employeesList = document.getElementById("employeesList");
      if (addEmployeeForm && employeesList && isMounted.current) {
        employeesList.classList.add("hideEmployeeList");
        addEmployeeForm.classList.add("showAddEmployeeForm");
      }
    }, 150);
  };

  const handleSetChosenBudgetRanges = (options) => {
    setChosenBudgetRanges(options);
  };

  /**
   * Handles chosing tiers.
   * Sets chosen tiers to state.
   * Sets chosen tierIds to state.
   * @param {object} options 
   */
  const handleSetChosenTiers = (options) => {
    let selectedTierIds = [];

    if (options && options.length) {
      selectedTierIds = options.map(tier => tier.id);
    }

    setChosenTiers(options);
    setChosenTierIds(selectedTierIds);
  };

  /**
   * Handles opening of error popup
   * - Sets error popup visibility state to true.
   * - Sets error data object.
   * @param {object} errorData 
   */
  const handleOpenErrorPopup = (errorMessage, errors) => {
    setErrorPopup(true);
    setErrorMessage(errorMessage);
    setErrors(errors);
  }

  /**
   * Handle closing of modal.
   */
  const handleCloseModal = () => {
    setErrorPopup(false)
  }

  const removeTierFilters = (tier) => {
    const chosenTiersFilters = chosenTiers.filter(el => { return tier.id !== el.id });
    const chosenTierIdsFilters = chosenTierIds.filter(el => { return tier.id !== el });

    setChosenTiers(chosenTiersFilters);
    setChosenTierIds(chosenTierIdsFilters);
  }

  const removeBudgetPercentageFilter = (budgetRange) => {
    const chosenBudgetRangesFilters = chosenBudgetRanges.filter(el => { return budgetRange.id !== el.id });

    setChosenBudgetRanges(chosenBudgetRangesFilters);
  }

  const removeAllFilters = () => {
    setChosenTiers([]);
    setChosenTierIds([])
    setChosenBudgetRanges([]);
  }

  /**
   * Handles opening of dropdown wrapper tooltip.
   * - Sets isDropdownOpen toggled state.
   * @param {Event} event 
   */
  const handleDropdownOpen = (event) => {
    event.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  }

  useClickOutside([tooltip], () => setIsDropdownOpen(false));  
 
  useEffect(() => {
    dispatch({ type:width });   
  }, [width]);


  /**
   * Handles opening of sort wrapper tooltip.
   * - Sets isMobileSortWrapperOpen toggled state.
   * @param {Event} event 
   */
  const handleSortOpen = (event) => {
    event.preventDefault();
    setIsMobileSortWrapperOpen(!isMobileSortWrapperOpen);
  }

  /**
   * Handles sorting mobile employees columns
   * - If there is a table header that is sorted, unsort it
   * - Dispatch action for table head reducer. Return state by action type.  
   * @param {Number} id 
   */
  const toggleSortFunc = id => { 
    const sortedUpTableHeader = tableHeaders.filter(header => header.id !== id && (header.isSortedUp !== null))[0];
    // if there is a table header that is sorted, unsort it
    !isEmpty(sortedUpTableHeader) && dispatch({ type: "unsort"});

    dispatch({ type: id });
  } 

  useClickOutside([tooltipActionsSortWrapper], () => setIsMobileSortWrapperOpen(false));

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [employees.length])

  /**
  * Handles the behavior when employees on the active page change
  * Users that are not included in the checkedMap are added with the value false
  * If at least one user is checked on the active page, set isChecked to true, otherwise false.
  */
  useEffect(() => {
    const checked = new Map(checkedMap);
    let numberOfSelectedOnPage = 0;
    employees.forEach(employee => checked.has(employee.user.id) 
        ? checked.get(employee.user.id) && numberOfSelectedOnPage++ 
        : !employee.user.isDisabled && checked.set(employee.user.id, false)      
    ); 
    
    numberOfSelectedOnPage > 0 ? setIsChecked(true) : setIsChecked(false);
    setCheckedMap(checked);
  }, [employees]);  
  
  /**
   * Handles the behavior when the checkedMap is change
   * If at least one user is checked on the active page, set isChecked to true, otherwise false.
   */
  useEffect(() => {
    let numberOfSelectedOnPage = 0;
    employees.forEach(employee => {
      checkedMap.has(employee.user.id) && checkedMap.get(employee.user.id) && numberOfSelectedOnPage++;
    }); 
    
    numberOfSelectedOnPage > 0 ? setIsChecked(true) : setIsChecked(false);
  }, [checkedMap]);
  
  const handleCheckboxOnChange = () => {
    const checked = new Map(checkedMap);
    !isChecked 
      ? employees.forEach(employee => !employee.user.isDisabled && checked.set(employee.user.id, true)) 
      : employees.forEach(employee => !employee.user.isDisabled && checked.set(employee.user.id, false));

    let total = 0;
    for (const x of checked.values()) {
      x && total ++;
    };
    
    setSelectedNumber(total);
    setCheckedMap(checked);
    setIsChecked(!isChecked);
  };

  return (
    <EmployeesStyles selectedColor = {(selectedNumber > 0) ? "#668CCC" : "#1D1D1D"}>
      <div className="employeeContentContainer">        
        <Header 
          headerTitle="Employees" 
          svgIcon={<EmployeesIcon fillColor="#1D3468" width="24" height="16.16"/>} 
        />
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        ) : (
          <div className="employeesContainer">
            <Card
              id="employeesList"
              padding="0"
              boxShadow="
									0 2px 4px -1px rgba(0, 0, 0, 0.2),
									0 1px 10px 0 rgba(0, 0, 0, 0.12),
									0 4px 5px 0 rgba(0, 0, 0, 0.14)
								"
            >
              <div className="firstMenu">
                <div className="buttonsWrapper">
                  <div className="searchIcon">
                    <SearchIcon style={{color: "lightgray"}}/>
                  </div>
                  <SearchInput
                    fetchData={fetchEmployees}
                    setSearch={setHrEmployeeSearch}
                    search={search}
                    setPagginationPage={setHrEmployeePage}
                    style={{ maxWidth: 400 }}
                    searchWrapperWidth={width < 500 ? '90%' : '61%'}
                    customClass='searchEmployees'
                    placeholder='Search employees'
                    placeholderColor='777777'
                  />                  
                  <Button
                    margin="0"                    
                    padding="14px 20px"
                    gap="10px"
                    color="#1D1D1D"
                    width="108px"
                    transparent={true}
                    onClick={() => setIsShowFilter(!isShowFilter)}
                    customClass="filterButton"
                  >
                    <FilterIconSvg/>
                    <p>Filters</p>                 
                  </Button>
                {isShowFilter && (
                  <div className="filtersContainer mobile">
                    <div className="closeFiltersButton" onClick={() => setIsShowFilter(false)}> X </div>
                    <div className="selectsContainer">
                      <MultiselectInput
                        name="tiers"
                        options={tiers}
                        placeholder='Filter by tiers'
                        customHeight="42px"
                        shouldHaveFullWidth={true}
                        handleChange={option => handleSetChosenTiers(option)}
                        selectedValues={chosenTiers}
                      />
                      <MultiselectInput
                        name="budgetPercentages"
                        options={budgetRangesOptions}
                        placeholder='Filter by used budget percentage'
                        customHeight="42px"
                        shouldHaveFullWidth={true}
                        handleChange={option => handleSetChosenBudgetRanges(option)}
                        selectedValues={chosenBudgetRanges}
                      />
                    </div>
                  </div>
                )}                  
                </div>
                <div className="buttonsContainer">
                  { width > 960 && <Button
                    margin="0"
                    padding="9px 25px"
                    onClick={openAddEmployee}
                    borderRadius="4px 0px 0px 4px"
                    borderRight="1px solid white"
                    height="48px"
                  >
                    <p className="addSign">+</p>
                    <p className="buttonText">Add Employee</p>
                  </Button>}
                  <div style={{ height: "48px"}} ref={tooltip}>
                    {(width > 960) ?
                      <Button
                      margin="0"
                      padding="10px"
                      borderRadius="0px 4px 4px 0px"
                      height="100%"
                      onClick={(e) => handleDropdownOpen(e)}
                    >
                      <span style={{ marginLeft: "6px" }}>
                        <ChevronIconDownSvg isOpen={isDropdownOpen} fill="white" />
                      </span>
                    </Button>
                    :<Button
                    margin="0"
                    padding="9px 11px"
                    onClick={(e) => handleDropdownOpen(e)}
                    borderRadius="4px"
                    borderRight="1px solid white"
                  >
                  <p className="addSign">+</p>                          
                  </Button>}
                    {isDropdownOpen && (
                      <div className="actionsDropdownWrapper">
                        <span
                          onClick={openAddEmployee}
                        >
                          <div className="addContainer">
                            <p className="buttonText">+</p>
                          </div>
                          <p className="buttonText">Add Employee</p>
                        </span>
                        <span
                          onClick={openModal}
                        >
                          <div className="addContainer">
                            <p className="buttonText">+</p>
                          </div>
                          <p className="buttonText">Add CSV</p>
                        </span>
                        <span
                          onClick={openUpdateModal}
                        >
                          <div className="addContainer">
                            <p className="buttonText">+</p>
                          </div>
                          <p className="buttonText">Update CSV</p>
                        </span>
                        <span
                          onClick={() => openAddBonusModal({
                            title: 'Add Bonuses',
                            selectName: "employees"
                          }, null)}
                        >
                          <div className="addContainer">
                            <p className="buttonText">+</p>
                          </div>
                          <p className="buttonText">Add bonus</p>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="firstMenuMobile">
                <div ref={tooltipActionsSortWrapper}>
                  <Button 
                    margin="0"
                    padding="12px 16px"
                    gap="8px"
                    transparent={true}
                    color="#777777"                  
                    onClick={(e) => handleSortOpen(e)}
                    borderRadius="4px"
                    customClass="sortMobileButton"
                  >
                    <SortIconSvg/>
                    <p>Sort</p>
                  </Button>
                  {isMobileSortWrapperOpen && (
                    <div className="actionsSortWrapper">
                      {tableHeaders.map(({id, isSortable, isSortedUp, headName}) => (
                        isSortable &&
                          <Button
                            margin="0"
                            padding="8px 10px"
                            key={id}
                            id={id}
                            onClick={() => toggleSortFunc(id)}
                            transparent
                            gap="4px"
                            color="rgb(7, 6, 6)"
                            customClass="sortButton"
                          >
                            <div className="chevronContainer">
                              <ChevronIconDownSvg 
                                isOpen={(isSortedUp === null) || isSortedUp ? false : true} 
                                fill={(isSortedUp === null) ? "#DBDBDB" : "#668CCC"}
                              />
                            </div>
                            <p className="buttonText">Sort by {headName}</p>                              
                          </Button>                          
                      ))}                        
                    </div>
                    )}
                </div>
                <Button 
                  margin="0"
                  padding="12px 13px"
                  transparent={true}
                  onClick={() => setIsShowFilter(!isShowFilter)}
                >
                  <FilterIconMobileSvg/>         
                </Button>                        
              </div>
              {(!isEmpty(chosenTiers) || !isEmpty(chosenBudgetRanges)) &&
                <div className="appliedFiltersContainer">
                  <div className="appliedFilters">
                    {!isEmpty(chosenTiers) && chosenTiers.map((tier, idx) => (
                      <div key={idx} className="appliedFilterPill">                          
                        <span>
                          {tier.name}
                        </span>
                        <span
                          className="removeFilter"
                          onClick={() => removeTierFilters(tier)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                    {!isEmpty(chosenBudgetRanges) && chosenBudgetRanges.map((budgetRange, idx) => (
                      <div key={idx} className="appliedFilterPill">                          
                        <span>
                          {budgetRange.name}
                        </span>
                        <span
                          className="removeFilter"
                          onClick={() => removeBudgetPercentageFilter(budgetRange)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                  </div>
                  <Button
                    margin="0" 
                    padding="0"
                    width="80px"
                    color="#2F68B9"
                    transparent={true}
                    customClass="clearButton" 
                    onClick={removeAllFilters}
                  >
                      <p>Clear All</p>
                  </Button>
                </div>
              }
              <div className="secondMenu">
                <div className="flexContainer">
                  <div className="flexContainer">  
                    <CheckboxInput 
                      onChange={handleCheckboxOnChange}
                      isChecked={isChecked}                       
                      name="selected"
                      width="18px"
                      height="18px"
                      cursor="pointer"
                    />
                    <p className="selected">{selectedNumber} Selected</p>
                  </div>
                  {/* 
                  <div className="flexContainer" onClick={() =>console.log('add')}>
                    <div className="plus">
                      <AddIconSvg/>
                    </div>
                    <p className="addBonus"> Add Bonus </p>
                  </div>
                  */}
                  <Button
                    margin="0"
                    padding="12px 16px"
                    width="119px"
                    height="48px"
                    transparent={true}
                    gap="10px"
                    onClick={openDisableUsersPopup}
                  >                    
                    <TrashIconSvg width = '17px' stroke = '#CC6666'/>                    
                    <p>Delete</p>
                  </Button>
                </div>
              </div>
              <div className="tableHead">
                {tableHeaders.map(
                  ({
                    id,
                    headName,
                    isSortable,
                    isSortedUp,
                    width,
                    justifyContent,
                    margin,
                    Component
                  }) => (
                    <TableHead
                      key={id}
                      id={id}
                      Component={Component}
                      tableHeadColName={headName}
                      isSortedUp={isSortedUp}
                      isSortable={isSortable}
                      colWidth={width}
                      justifyContent={justifyContent}
                      margin={margin}
                      toggleSortFunc={toggleSortFunc}
                    />
                  )
                )}
              </div>
              <div className="employeeListWrapper">
                {!isEmpty(employees) ? (
                  employees.map(employee => (
                    <EmployeeListItem
                      key={employee.id}
                      fullName={`${employee.user.firstName} ${employee.user.lastName}`}
                      employeeId={employee.id}
                      userId={employee.user.id}
                      openAddBonusModal={openAddBonusModal}
                      openDisableUserPopup={openDisableUserPopup}
                      isRowDisabled={employee.user.isDisabled}
                      tier={employee.tier.name}
                      tokens={employee.totalTokens}
                      remainingTokens={employee.remainingTokens >= 0 ? employee.remainingTokens : 0}
                      budgetSpentPercentage={`${employee.budgetSpentPercentage}%`}
                      userEmail={employee.user.email}
                      gender={employee.gender}
                      cities={employee.cities}
                      team={employee.team}
                      relationship={employee.relationship}
                      bornDate={employee.bornDate}
                      firstDayAtWork={employee.firstDayAtWork}
                      fetchData={fetchEmployees}
                      contact={employee.contact}
                      bonusAmount={employee.bonusAmount}
                      setSelectedNumber={setSelectedNumber}
                      checkedMap={checkedMap}
                      setCheckedMap={setCheckedMap}
                    />
                  ))
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    No results found
                  </div>
                )}
              </div>
              <div className="paginationWrapper">
                <Pagination
                  currentPage={page}
                  totalSize={count}
                  sizePerPage={LIMIT}
                  changeCurrentPage={page => setHrEmployeePage(page)}
                  theme="border-bottom"
                />
              </div>
            </Card>
            {isAddEmployeeOpen && (
              <div id="addEmployeeForm">
                <AddEmployee
                  closeForm={setIsAddEmployeeOpen}
                  fetchEmployees={fetchEmployees}
                />
                <div style={{ height: 30 }} />
              </div>
            )}
          </div>
        )}
      </div>
      {isModalOpen && (
        <Modal closeModal={closeModal}>
          <AddEmployeesWithCSV
            closeModal={closeModal}
            fetchEmployees={fetchEmployees}
            openErrorPopup={handleOpenErrorPopup}
          />
        </Modal>
      )}
      {isUpdateModalOpen && (
        <Modal closeModal={closeUpdateModal}>
          <UpdateEmployeesWithCSV
            closeModal={closeUpdateModal}
            fetchEmployees={fetchEmployees}
            openErrorPopup={handleOpenErrorPopup}
          />
        </Modal>
      )}
      {errorPopup && (
        <Modal
          isCloseVisable={false}
        >
          <AddEmployeeWithCSVError
            closeModal={handleCloseModal}
            cities={errorMessage.data}
            message={errorMessage.message}
            errors={errors}
          />
        </Modal>
      )}
      {disableUserPopup && (
        <Modal isCloseVisable={true} closeModal={() => setDisableUserPopup(false)}>
          <DisableUserModal
            userId={selectedUserId}
            closeModal={() => setDisableUserPopup(false)}
            fetchData={fetchEmployees}
          />
        </Modal>
      )}
      {disableUsersPopup && (
        <Modal isCloseVisable={true} closeModal={() => setDisableUsersPopup(false)}>
          <DisableUsersModal
            closeModal={() => setDisableUsersPopup(false)}
            fetchData={fetchEmployees}
            userIdsMap={checkedMap}
            text="employees"
            setSelectedNumber={setSelectedNumber}
          />
        </Modal>
      )}
      {modalType && (
        <Modal closeModal={closeAddBonusModal}>
          <AddBonus
            modalType={modalType}
            closeModal={closeAddBonusModal}
            employeeId={selectedEmployeeId}
          />
        </Modal>
      )}
    </EmployeesStyles>
  );
};

const mapStateToProps = state => {
  return {
    employees: state.employees.employees,
    page: state.employees.page,
    count: state.employees.count,
    search: state.employees.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setHrEmployees: (employees, count) =>
      dispatch(actionCreators.setHrEmployees(employees, count)),
    setHrEmployeePage: page => dispatch(actionCreators.setHrEmployeePage(page)),
    setHrEmployeeSearch: search =>
      dispatch(actionCreators.setHrEmployeeSearch(search))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
