import { apiService } from "../api.service";
import {
  Budget,
  TotalBudget,
  BudgetSpending,
  CategoryBudget,
  CompanyUsage,
  SyncedEmployees,
  Combination
} from "../../../models/domain";

export const fetchBudgetPerTiers = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/budget-via-tiers?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new Budget(response.data);
};

export const fetchTotalBudget = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/total-budget?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new TotalBudget(response.data);
};

export const fetchBudgetSpending = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/budget-utilization?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new BudgetSpending(response.data);
};

export const fetchBudgetPerCategories = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/budget-via-category?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new CategoryBudget(response.data);
};

export const fetchCompanyUsage = async () => {
  const response = await apiService.get("/dashboard/widget/company/stats");

  if (response.hasError) return response;

  return new CompanyUsage(response.data);
};

export const getStatistics = async (searchDateString, reportType) => {
  const response = await apiService.get(
    `/dashboard/hr/report/statistics?date=${searchDateString}&report_type=${reportType}`
  );

  return response;
};

export const getRefundStatistics = async (searchDateString, reportType) => {
  const response = await apiService.get(
    `/dashboard/hr/report/refund-statistics?date=${searchDateString}&report_type=${reportType}`
  );

  return response;
};

export const fetchSyncedEmployees = async () => {
  const response = await apiService.get("/dashboard/widget/sync/benefits");

  if (response.hasError) return response;

  return new SyncedEmployees(response.data);
};

export const fetchTopBenefitsData = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/top-benefits?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new Budget(response.data);
};

export const fetchUniqueUsedCombinations = async () => {
  const response = await apiService.get(
    "/dashboard/widget/unique/used/combination"
  );

  if (response.hasError) return response;

  return new Combination(response.data);
};

export const fetchUniqueCombinationsPerTiers = async () => {
  const response = await apiService.get("/dashboard/widget/unique/combination");

  if (response.hasError) return response;

  return new Combination(response.data);
};
