import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { InputLabel, CheckboxInput } from "../../../../components";

import EditBenefitOptionsStyles from "./EditBenefitOptionsStyles";

const EditBenefitOptions = ({
  tiers,
  availableTiers,
  disabled,
  handleChange
}) => (
  <EditBenefitOptionsStyles>
    <p className="tiersLabel">Access :</p>
    <div className="checkboxesContainer">
      {tiers.length > 0 ? (
        tiers.map(tier => (
          <div className="checkboxContainer" key={tier.id}>
            <CheckboxInput
              isChecked={availableTiers.includes(tier.id)}
              disabled={!disabled}
              onChange={() => handleChange(tier.id)}
            />
            <InputLabel
              label={`${tier.name} (${tier.tokens} Tokens)`}
              margin="0 0 0 10px"
              shouldHaveColon={false}
            />
          </div>
        ))
      ) : (
        <div className="checkboxContainer">
          <p className="message">
            You need to create tiers to manage accessibility of benefit groups
          </p>
        </div>
      )}
    </div>
  </EditBenefitOptionsStyles>
);

EditBenefitOptions.propTypes = {
  tiers: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  handleChange: PropTypes.func
};

const mapStateToProps = state => {
  return {
    tiers: state.benefits.editPageState.tiers,
    disabled: state.benefits.editPageState.isBenefitGroupActive
  };
};

export default connect(mapStateToProps, null)(EditBenefitOptions);
