import * as actionTypes from "../constants/actionTypes/app.constants";

export const login = payload => {
  return { type: actionTypes.LOGIN, payload };
};

export const switchUserRole = roleObject => {
  return { type: actionTypes.SWITCH_USER_ROLE, roleObject }
}

export const setUser = payload => {
  return { type: actionTypes.SET_USER, payload };
};

export const logout = () => {
  return { type: actionTypes.LOGOUT };
};

export const finishOnboarding = () => {
  return { type: actionTypes.FINISH_USER_ONBOARDING };
};

export const finishEmployeeOnboarding = () => {
  return { type: actionTypes.FINISH_EMPLOYEE_ONBOARDING };
};

export const acceptPrivacyPolicy = () => {
  return { type: actionTypes.USER_ACCEPT_PRIVACY_POLICY };
};

export const setEmployeeTokens = employeeTokens => {
  return {
    type: actionTypes.SET_USER_TOKENS,
    employeeTokens
  };
};

export const setSystemNotifications = systemNotifications => {
  return {
    type: actionTypes.SET_USER_NOTIFICATIONS,
    systemNotifications
  };
};

export const popSystemNotifications = () => {
  return {
    type: actionTypes.POP_SYSTEM_NOTIFICATIONS
  };
};

export const syncUser = () => {
  return {
    type: actionTypes.SYNC_USER
  };
};

export const setUserThemes = themes => {
  return {
    type: actionTypes.SET_USER_THEMES,
    themes
  };
};
