class BenefitTag {
	constructor(benefitTagData = {}) {
		this.id = benefitTagData.id || null
		this.name = benefitTagData.name || ''
	}
}

class CompanyTag {
	constructor(companyTagData = {}) {
		this.id = companyTagData.id || null
		this.name = companyTagData.name || ''
	}
}

class Category {
	constructor(benefitCategoryData = {}) {
		this.id = benefitCategoryData.id || null
		this.name = benefitCategoryData.name || ''
		this.description = benefitCategoryData.description || ''
	}
}


export { BenefitTag, CompanyTag, Category }
