import { apiService } from "../api.service";

// Responses
import {
  UsersResponse,
  CompaniesResponse,
  TiersResponse,
  EmployeeResponse,
  RolesResponse,
  BranchesResponse,
} from "../../../models/server/responses";

// Models
import { DropDown, Employee, User, UserRole } from "../../../models/domain";

// Payloads
import { EmployeePayload, UserPayload } from "../../../models/server/payloads";

// Requests
import { UserRequest } from "../../../models/server/requests";

// Utils
import { isEmpty } from "../../general.utils";

export const getUsers = async ({ limit, page, searchText, orderColumns, companyIds, roleIds }) => {
  let queryParams = "";
  let companiesQuery = "";
  let rolesQuery = "";

  if (limit) {
    queryParams += `limit=${limit}`;
  }

  if (page) {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}page=${page}`;
  }

  if (!isEmpty(companyIds) && Array.isArray(companyIds)) {
    companiesQuery = companyIds.reduce(
      (query, company) =>
        `${!isEmpty(query) ? `${query}&` : ""}company_id=${company}`,
      ""
    );
  }

  if (!isEmpty(roleIds) && Array.isArray(roleIds)) {
    rolesQuery = roleIds.reduce(
      (query, role) =>
        `${!isEmpty(query) ? `${query}&` : ""}role_id=${role}`,
      ""
    );
  }

  if (!isEmpty(companiesQuery)) {
    queryParams += `${!isEmpty(queryParams) ? "&" : null}${companiesQuery}`;
  }

  if (!isEmpty(rolesQuery)) {
    queryParams += `${!isEmpty(queryParams) ? "&" : null}${rolesQuery}`;
  }

  if (orderColumns.length > 0) {
    queryParams += orderColumns.reduce(
      (acc, column) =>
        `${acc}&order=${column.field} ${column.isSortedUp ? "ASC" : "DESC"}`,
      ""
    );
  }

  let response = await apiService.get(
    `/users?${queryParams}&search=${searchText}`
  );

  if (response.hasError) return response;
  response = new UsersResponse(response);
  return {
    users: response.users,
    count: response.count,
    pages: response.pages
  };
};

/**
 * Fetch list of roles.
 * @returns {UserRole[]}
 */
export const getRolesForDropdown = async () => {
  let response = await apiService.get("/users/roles");
  if (response.hasError) return response;

  response = new RolesResponse(response);

  return response.roles.map(role => new UserRole(role));
};

export const getCompanyOptions = async (
  page = 1,
  limit = 20
) => {
  let response = await apiService.get(`/company/get?page=${page}&limit=${limit}`);
  if (response.hasError) return response;
  response = new CompaniesResponse(response);
  return {
    companies: response.companies.map(company => new DropDown(company)),
    pages: response.pages
  };
};

export const getTierOptions = async companyId => {
  let response = await apiService.get(`/company/tiers?company_id=${companyId}`);
  if (response.hasError) return response;
  response = new TiersResponse(response);
  return response.tiers.map(tier => new DropDown(tier));
};

export const getSingleUser = async ({ id }) => {
  let url = `/users/${id}`;
  let response = await apiService.get(url);
  if (response.hasError) return response;

  return new User(response.data);
};

export const createUser = async payload => {
  let payloadData = new UserPayload(payload);
  let url = "/users/register";
  const response = await apiService.post(url, payloadData);
  return response;
};

export const updateUser = async ({ payload, id }) => {
  let payloadData = new UserPayload(payload);
  let url = `/users/edit/${id}`;
  const response = await apiService.put(url, payloadData);
  return response;
};

/**
 * Disables user.
 * @param {number} userId
 * @param {Date} scheduleDeleteAt
 * @returns {import("axios").AxiosResponse}
 */
export const disableUser = async ({ userId, scheduleDeleteAt }) => {
  let payloadData = {
    schedule_delete_at: scheduleDeleteAt
  };
  let url = `/users/disable/${userId}`;

  const response = await apiService.patch(url, payloadData);

  return response;
}

/**
 * Disables users.
 * @param {number[]} userIds
 * @param {Date} scheduleDeleteAt
 * @returns {import("axios").AxiosResponse}
 */
export const disableUsers = async ({ userIds, scheduleDeleteAt }) => {
  let payloadData = {
    schedule_delete_at: scheduleDeleteAt,
    user_ids: userIds
  };
  let url = `/users/disable-users`;

  const response = await apiService.patch(url, payloadData);
  
  return response;  
}

/**
 * Resends email verification link.
 * @param {number} userId
 */
export const resendVerificationLink = async userId => {
  const response = await apiService.get(`/users/resend-confirmation/${userId}`);

  return response;
};

/**
 * 
 * @param {*} userId 
 * @returns 
 */
export const enableUser = async userId => {
  let url = `/users/enable/${userId}`;
  const response = await apiService.patch(url);

  return response;
};

export const getCompanyBranches = async companyId => {
  const response = await apiService.get(`/company/branches?company_id=${companyId}`);
  if (response.hasError) return response;

  return new BranchesResponse(response);
};
