/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
// components
import { Header, Card, Modal } from "../../../components";
import { Section, FormRenderer } from "./shared";
// styles
import SettingsStyles from "./SettingsStyles";
import { override } from "../../../services/loadingOverride.styles";
// services
import { openModal, closeModal } from "./settings.services";
import * as benefitTagActionCreators from "../../../actions/admin/benefitTags.actions";
import * as companyTagActionCreators from "../../../actions/admin/companyTags.actions";
import * as categoriesActionCreators from "../../../actions/admin/categories.actions";
import * as benefitTagService from "../../../services/api/admin/benefitTags.service";
import * as companyTagService from "../../../services/api/admin/companyTags.service";
import * as categoriesService from "../../../services/api/admin/categories.service";
// context
import { SettingsContext } from "../../../modules/superAdmin/settings/context/settings.context";
import { useDisableBodyScroll } from "../../../hooks/useDisableBodyScroll";

let mountedState = null;
let cardOpacity = null;

const Settings = ({
  setBenefitTags,
  deleteBenefitTag,
  setCompanyTags,
  deleteCompanyTag,
  setCategories,
  deleteCategory,
  categories,
  companyTags,
  benefitTags
}) => {
  const [modalType, setModalType] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [chosenId, setChosenID] = useState(undefined);
  const isMounted = useRef(true);

  useDisableBodyScroll(modalType);

  // const {
  // 	companyTags,
  // 	companyTagsActions: { setCompanyTags, deleteCompanyTag},
  // 	benefitTags,
  // 	benefitTagsActions: { setBenefitTags, deleteBenefitTag},
  // 	categoriesState,
  // 	categoriesActions: { setCategories, deleteCategory}
  // } = useContext(SettingsContext)
  // const { user } = useContext(UserContext)

  const fetchData = async () => {
    const companyTagsData = await companyTagService.getCompanyTags();
    const benefitTagsData = await benefitTagService.getBenefitTags();
    const categoriesData = await categoriesService.getCategories();

    mountedState = setTimeout(() => {
      if (
        benefitTagsData &&
        companyTagsData &&
        categoriesData &&
        isMounted.current
      ) {
        setCompanyTags(companyTagsData);
        setBenefitTags(benefitTagsData);
        setCategories(categoriesData.categories);
        setIsLoading(false);
      }
    }, 100);
    cardOpacity = setTimeout(() => {
      const settingsCard = document.getElementById("settingsCard");
      if (isMounted.current && settingsCard) {
        settingsCard.style.opacity = 1;
      }
    }, 200);
  };

  useEffect(() => {
    fetchData();
    return () => {
      isMounted.current = false;
      clearTimeout(mountedState);
      clearTimeout(cardOpacity);
    };
  }, []);

  return (
    <SettingsStyles>
      <div className="tagsSectionContainer">
        <Header headerTitle="Settings" />
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        ) : (
          <div className="tagsContent">
            <Card id="settingsCard">
              <Section
                textAndLineTitle="Company tags"
                buttonText="Add Company tag"
                listItems={companyTags}
                buttonOnCLick={() => {
                  openModal({ setModalType, modalType: "ADD_COMPANY_TAG" });
                }}
                editItem={id => {
                  setChosenID(id);
                  openModal({ setModalType, modalType: "EDIT_COMPANY_TAG" });
                }}
                removeItem={async id => {
                  const result = confirm(
                    "Are you sure you want to delete this company tag?"
                  );
                  if (result) {
                    const response = await companyTagService.removeCompanyTag(
                      id
                    );

                    if (response.hasError) {
                      return toast.error(
                        response.errorMessage
                          ? response.errorMessage
                          : "Deleting company tag failed"
                      );
                    }

                    deleteCompanyTag(id);
                    toast.success("Company tag successfully deleted!");
                  }
                }}
              />
              <Section
                textAndLineTitle="Benefit tags"
                buttonText="Add Benefit tag"
                listItems={benefitTags}
                buttonOnCLick={() => {
                  openModal({ setModalType, modalType: "ADD_BENEFIT_TAG" });
                }}
                editItem={id => {
                  setChosenID(id);
                  openModal({ setModalType, modalType: "EDIT_BENEFIT_TAG" });
                }}
                removeItem={async id => {
                  const result = confirm(
                    "Are you sure you want to delete this benefit tag?"
                  );
                  if (result) {
                    const response = await benefitTagService.removeBenefitTag(
                      id
                    );

                    if (response.hasError) {
                      return toast.error(
                        response.errorMessage
                          ? response.errorMessage
                          : "Deleting benefit tag failed"
                      );
                    }

                    deleteBenefitTag(id);
                    toast.success("Benefit tag successfully deleted!");
                  }
                }}
              />
              <Section
                textAndLineTitle="Benefit group categories"
                buttonText="Add Benefit group category"
                listItems={categories}
                buttonOnCLick={() => {
                  openModal({ setModalType, modalType: "ADD_CATEGORY" });
                }}
                editItem={id => {
                  setChosenID(id);
                  openModal({ setModalType, modalType: "EDIT_CATEGORY" });
                }}
                removeItem={async id => {
                  const result = confirm(
                    "Are you sure you want to delete this benefit group category?"
                  );

                  if (result) {
                    const response = await categoriesService.removeCategory(id);

                    if (response.hasError) {
                      return toast.error(
                        response.errorMessage
                          ? response.errorMessage
                          : "Deleting benefit group category failed"
                      );
                    }

                    deleteCategory(id);
                    toast.success("Category successfully deleted!");
                  }
                }}
              />
            </Card>
          </div>
        )}
      </div>
      {modalType && (
        <Modal closeModal={() => closeModal({ setModalType })}>
          <FormRenderer
            modalType={modalType}
            chosenId={chosenId}
            closeModal={() => closeModal({ setModalType })}
          />
        </Modal>
      )}
    </SettingsStyles>
  );
};

const mapStateToProps = state => {
  return {
    benefitTags: state.benefitTags.tags,
    companyTags: state.companyTags.tags,
    categories: state.categories.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setBenefitTags: benefitTags =>
      dispatch(benefitTagActionCreators.setBenefitTags(benefitTags)),
    deleteBenefitTag: benefitTag =>
      dispatch(benefitTagActionCreators.deleteBenefitTag(benefitTag)),
    setCompanyTags: companyTags =>
      dispatch(companyTagActionCreators.setCompanyTags(companyTags)),
    deleteCompanyTag: companyTag =>
      dispatch(companyTagActionCreators.deleteCompanyTag(companyTag)),
    setCategories: categories =>
      dispatch(categoriesActionCreators.setCategories(categories)),
    deleteCategory: category =>
      dispatch(categoriesActionCreators.deleteCategory(category))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
