import React from "react";
import ReactTooltip from "react-tooltip";
// styles
import { BenefitSubscribeButtonsContainerStyles } from "./benefitSubscribeButtonsContainer.styles";
// components
import BenefitSubscribeButton from "./BenefitsSubscribeButton";
import VoucherSubscribeButton from "./VoucherSubscribeButton";
// builder constants
import { BenefitExpirationTypeEnum } from "../../../../../constants/benefit.constants";
import { isEmpty } from "../../../../../services/general.utils";

const BenefitSubscribeButtonsContainer = ({
  benefit,
  status,
  isPreview = false,
  enableInstalments,
  setIsLoading,
  benefitGroupType,
  setShowPDFBill,
  setVoucherReimbursementData,
}) => {
  return (
    <BenefitSubscribeButtonsContainerStyles
      doesHaveMinimumContractPeriod={benefit.minimumContractPeriod}
      enableInstalments={enableInstalments}
    >
      {!isEmpty(benefit.minimumContractPeriod) && (
        <>
          <small
            className="minimum-contract-period"
            data-tip
            data-for="mcpTooltip"
          >
            *Minimum contract period: {benefit.minimumContractPeriod} months
          </small>
          <ReactTooltip
            id="mcpTooltip"
            place="bottom"
            effect="solid"
            className="mcpTooltip"
            backgroundColor="black"
            borderColor="black"
            arrowColor="black"
          >
            <span style={{ color: "black" }}>
              You won't be able to unsubscribe until the commitment period ends.
              <br />
              Once the commitment period ends you will be automatically unsubscribed.
            </span>
          </ReactTooltip>
        </>
      )}
      {enableInstalments && benefit.instalments && (
        <small className="minimum-contract-period">
          *Price is split in instalments as shown above.
        </small>
      )}
      {benefit && benefit.expiration === BenefitExpirationTypeEnum.VOUCHER ? (
        <VoucherSubscribeButton
          benefit={benefit}
          status={status}
          isPreview={isPreview}
          setIsLoading={setIsLoading}
          setShowPDFBill={setShowPDFBill}
          setVoucherReimbursementData={setVoucherReimbursementData}
        />
      ) : (
        <BenefitSubscribeButton
          benefit={benefit}
          status={status}
          isPreview={isPreview}
          setIsLoading={setIsLoading}
          benefitGroupType={benefitGroupType}
        />
      )}
    </BenefitSubscribeButtonsContainerStyles>
  );
};

export default BenefitSubscribeButtonsContainer;
