import React, { useEffect } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";
// components
import {
  TextAndLine,
  BenefitCard,
  ListPlaceHolder,
  openModalAnim
} from "../../../../components";
// services
import * as api from "../../../../services/api/employee/employeeBenefits.services";
import * as benefitGroupService from "../../../../services/api/admin/benefitGroups.service";
// actions
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";
// constants
import { PUBLIC } from "../../../../builders/benefitGroups/benefitGroup.types";

const BrowseBenefitGroupsStyles = styled.div`
  .benefitList {
    display: flex;
    justify-content: center;
    max-width: 1440px;
    flex-wrap: wrap;
    margin: 30px 0 0 0;
  }
`;

const BrowseBenefitGroups = ({
  benefitBrowseGroups,
  benefitBrowseGroupsTotalPages,
  benefitBrowseGroupsPage,
  benefitBrowseGroupsCount,
  benefitCompanyGroups,
  benefitCompanyGroupsTotalPages,
  benefitCompanyGroupsPage,
  benefitCompanyGroupsCount,
  appliedChosenCategoryIds,
  appliedCityIds,
  appliedRemoteFilter,
  appliedChosenPriceRange,
  appliedBenefitExpirationTypes,
  appliedSearchFilter,
  appliedOrderSort,
  appliedKeySort,
  setAditionalBenefitGroups,
  openBenefitGroupModal,
  fetchData,
  fetchAlreadyUsedBenefits,
  benefitGroupType,
  isPageLoading
}) => {
  const fetchMoreBenefitGroups = async (
    chosenCategories = appliedChosenCategoryIds,
    priceRange = appliedChosenPriceRange,
    chosenCities = appliedCityIds,
    remoteFilter = appliedRemoteFilter,
    chosenBenefitExpirationTypes = appliedBenefitExpirationTypes,
    search = appliedSearchFilter,
    order = appliedOrderSort,
    key = appliedKeySort
  ) => {
    let benefitGroupsPage = benefitGroupType === PUBLIC ? benefitBrowseGroupsPage : benefitCompanyGroupsPage;
    const response = await api.getBenefitGroups({
      chosenCategories,
      page: benefitGroupsPage + 1,
      priceRange,
      chosenCities,
      remoteFilter,      
      chosenBenefitExpirationTypes,
      search,
      order,
      key,
      type: benefitGroupType,
    });
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching Benefit groups failed."
      );
    }
    setAditionalBenefitGroups(
      response.groups,
      response.count,
      response.pages,
      benefitGroupsPage + 1,
      benefitGroupType
    );
  };

  useEffect(() => {
    if (
      document.body.clientHeight <= window.innerHeight && (
        benefitBrowseGroupsPage < benefitBrowseGroupsTotalPages ||
        benefitCompanyGroupsPage < benefitCompanyGroupsTotalPages
      )
    ) {
      fetchMoreBenefitGroups();
    }
  }, []);

  /**
   * Handles opening of modal after click on benefit group card.
   * If benefit group is marked as new, sends request to DB for updating status.
   * Refetches data.
   * Sends request to DB for benefits from that group that user is already using
   * @param {BenefitGroup} chosenBenefitGroup
   */
  const openModal = async chosenBenefitGroup => {
    if (chosenBenefitGroup.isBenefitGroupNew) {
      await benefitGroupService.markNewBenefitGroupAsSeen(
        chosenBenefitGroup.id
      );
      fetchData();
    }

    fetchAlreadyUsedBenefits(chosenBenefitGroup.id);

    openBenefitGroupModal(chosenBenefitGroup);
    // Show modal card after 100ms
    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  return (
    <BrowseBenefitGroupsStyles>
      <TextAndLine title={benefitGroupType === PUBLIC ? "Browse Benefits" : "Company Benefits"} />
      {isPageLoading ? (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <BeatLoader
            size={25}
            color="#123abc"
            loading={isPageLoading}
          />
        </div>
      ) : benefitGroupType === PUBLIC 
      ? benefitBrowseGroups?.length ? (
          <InfiniteScroll
            className="benefitList"
            dataLength={benefitBrowseGroups.length}
            next={fetchMoreBenefitGroups}
            hasMore={benefitBrowseGroupsPage < benefitBrowseGroupsTotalPages}
            loader={<h4>Loading...</h4>}
          >
            {benefitBrowseGroups.map(benefitGroup => (
              <BenefitCard
                key={benefitGroup.id}
                benefitDetails={benefitGroup}
                onClick={() => openModal(benefitGroup)}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <div style={{ height: "calc(100vh - 240px" }}>
            <ListPlaceHolder />
          </div>
        )
      : benefitCompanyGroups?.length ? (
        <InfiniteScroll
          className="benefitList"
          dataLength={benefitCompanyGroups.length}
          next={fetchMoreBenefitGroups}
          hasMore={benefitCompanyGroupsPage < benefitCompanyGroupsTotalPages}
          loader={<h4>Loading...</h4>}
        >
          {benefitCompanyGroups.map(benefitGroup => (
            <BenefitCard
              key={benefitGroup.id}
              benefitDetails={benefitGroup}
              onClick={() => openModal(benefitGroup)}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <div style={{ height: "calc(100vh - 240px" }}>
          <ListPlaceHolder />
        </div>
      )}
    </BrowseBenefitGroupsStyles>
  );
};

BrowseBenefitGroups.propTypes = {
  benefitBrowseGroups: PropTypes.arrayOf(PropTypes.shape({})),
  benefitBrowseGroupsTotalPages: PropTypes.number,
  benefitBrowseGroupsPage: PropTypes.number,
  benefitCompanyGroups: PropTypes.arrayOf(PropTypes.shape({})),
  benefitCompanyGroupsTotalPages: PropTypes.number,
  benefitCompanyGroupsPage: PropTypes.number,
  appliedChosenCategoryIds: PropTypes.arrayOf(PropTypes.number),
  appliedCityIds: PropTypes.arrayOf(PropTypes.number),
  appliedRemoteFilter: PropTypes.bool,
  appliedChosenPriceRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  appliedBenefitExpirationTypes: PropTypes.arrayOf(PropTypes.string),
  appliedSearchFilter: PropTypes.string,
  appliedOrderSort: PropTypes.string,
  appliedKeySort: PropTypes.string,
  setAditionalBenefitGroups: PropTypes.func,
  openBenefitGroupModal: PropTypes.func,
  fetchData: PropTypes.func,
  fetchAlreadyUsedBenefits: PropTypes.func,
  benefitGroupType: PropTypes.string,  
  isPageLoading: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    benefitBrowseGroups: state.employeeBenefitsPage.benefitsBrowse.benefitGroups,
    benefitBrowseGroupsCount:
      state.employeeBenefitsPage.benefitsBrowse.benefitGroupsCount,
    benefitBrowseGroupsTotalPages:
      state.employeeBenefitsPage.benefitsBrowse.benefitGroupsTotalPages,
    benefitBrowseGroupsPage:
      state.employeeBenefitsPage.benefitsBrowse.benefitGroupsPage,
    benefitCompanyGroups: state.employeeBenefitsPage.benefitsCompany.benefitGroups,
    benefitCompanyGroupsCount:
      state.employeeBenefitsPage.benefitsCompany.benefitGroupsCount,
    benefitCompanyGroupsTotalPages:
      state.employeeBenefitsPage.benefitsCompany.benefitGroupsTotalPages,
    benefitCompanyGroupsPage:
      state.employeeBenefitsPage.benefitsCompany.benefitGroupsPage,
    appliedChosenCategoryIds:
      state.employeeBenefitsPage.filters.appliedChosenCategoryIds,
    appliedCityIds: state.employeeBenefitsPage.filters.cityIds,
    appliedRemoteFilter: state.employeeBenefitsPage.filters.isRemote,
    appliedChosenPriceRange:
      state.employeeBenefitsPage.filters.appliedChosenPriceRange,
    appliedBenefitExpirationTypes:
      state.employeeBenefitsPage.filters.expirations,
    appliedSearchFilter: state.employeeBenefitsPage.filters.search,
    appliedOrderSort: state.employeeBenefitsPage.filters.order,
    appliedKeySort: state.employeeBenefitsPage.filters.key,
    isApplyFilterClicked:
      state.employeeBenefitsPage.filters.isApplyFilterClicked,
    benefitGroupType:
      state.employeeBenefitsPage.pageFrontEndStates.benefitGroupType,
    isPageLoading:
      state.employeeBenefitsPage.pageFrontEndStates.isPageLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAditionalBenefitGroups: (
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsTotalPages,
      benefitGroupsPage,
      benefitGroupType
    ) =>
      dispatch(
        actionCreators.setAditionalBenefitGroups(
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupsPage,
          benefitGroupType
        )
      ),
    setBenefitGroupsPage: benefitGroupsPage =>
      dispatch(actionCreators.setBenefitGroupsPage(benefitGroupsPage)),
    openBenefitGroupModal: chosenBenefitGroup =>
      dispatch(actionCreators.openBenefitGroupModal(chosenBenefitGroup))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowseBenefitGroups);
