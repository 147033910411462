import { HrEmployee } from '../hrEmployees.entity'

const hrEmployeesInitalState = []

const actionTypes = {
	setHrEmployees: 'SET_HR_EMPLOYEES',
	addEmployee: 'ADD_EMPLOYEE'
}

const hrEmployeesReducer = (state = hrEmployeesInitalState, action) => {
	switch (action.type) {
		case actionTypes.setHrEmployees:
			return action.payload.hrEmployees
		case actionTypes.addEmployee:
			return [...state, new HrEmployee(action.payload.newEmployee)]
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}

export { hrEmployeesInitalState, hrEmployeesReducer, actionTypes }
