import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// components and animations
import { closeModalAnim } from "../../../../../components";
import BenefitSubscribeQuantity from "./BenefitSubscribeQuantity/BenefitSubscribeQuantity";
// assets
import { CheckIcon, TokenIcon } from "../../../../../assets/Icons";
// services
import * as api from "../../../../../services/api/employee/employeeBenefits.services";
import * as employeeService from "../../../../../services/api/employee/employeeBenefits.services";
import * as userApiService from "../../../../../services/api/auth.service";
import * as benefitGroupService from "../../../../../services/api/admin/benefitGroups.service";
// actions
import * as actionCreators from "../../../../../actions/employee/employeeBenefits.actions";
import * as appActionCreators from "../../../../../actions/app.actions";
import { PENDING_DEACTIVATION } from "../../../../../builders/benefitGroups/benefitGroup.status";
import { BenefitExpirationTypeEnum } from "../../../../../constants/benefit.constants";
// Utils
import { isEmpty } from "../../../../../services/general.utils";
import {
  LOCAL,
  PUBLIC
} from "../../../../../builders/benefitGroups/benefitGroup.types";

const BenefitSubscribeButton = ({
  benefitGroup,
  benefit,
  appliedChosenCategoryIds,
  appliedCityIds,
  appliedRemoteFilter,
  appliedChosenPriceRange,
  setBenefitGroupsAndSubscribedBenefitGroups,
  closeBenefitGroupModal,
  setEmployeeTokenCount,
  status,
  setSystemNotifications,
  user,
  isPreview = false,
  updateBenefitGroup,
  setSubscribedBenefitGroups,
  setIsLoading,
  benefitGroupType
}) => {
  const [isSubscribedButtonHovered, setIsSubscribedButtonHovered] = useState(
    false
  );
  const [IsButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [quantity, setQuantity] = useState(1);

  /**
   * Calculates price user will pay for benefit.
   * @returns {number}
   */
  const calculateBenefitPrice = () => {
    if (
      benefit.instalments &&
      benefitGroup.doesBenefitGroupHaveInstalments &&
      benefitGroup.enableInstalments &&
      benefit.expiration === "one time"
    ) {
      return quantity * (benefit.tokenPrice / benefit.instalments);
    }

    return quantity * benefit.tokenPrice;
  };
  
  /**
   * * If VAT is defined, include VAT in the price the user will pay for the benefit   
   */
  const calculatedPrice = useMemo((    
  ) => user.companyVatEnabled && benefit.vat 
    ? parseFloat((calculateBenefitPrice() * (1 + benefit.vat / 100)).toFixed(2))
    : calculateBenefitPrice()
  , [quantity]);

  const closeModal = () => {
    closeModalAnim();
    // delays change of state until animation is done
    setTimeout(() => {
      closeBenefitGroupModal();
    }, 350);
  };

  const subscribeToBenefit = async () => {
    setIsButtonsDisabled(true);
    setIsLoading(true);

    const response = await api.subscribeOrUnsubscribeToBenefit({
      benefitGroupId: benefitGroup.id,
      benefitId: benefit.id,
      subscribe: true,
      quantity: quantity
    });
    if (response.hasError) {
      setIsButtonsDisabled(false);
      setIsLoading(false);

      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Subscribe to benefit failed."
      );
    }

    const [
      benefitGroupResponse,
      subscribedBenefitGroupsResponse,
      employeeTokens,
      notificationsResponse
    ] = await Promise.all([
      benefitGroupService.refreshBenefitGroup(benefitGroup.id),
      api.getSubscribedBenefits(),
      employeeService.getEmployeeTokenCount(),
      userApiService.getUnreadNotifications()
    ]);

    if (employeeTokens.hasError) {
      return toast.error(
        employeeTokens.errorMessage
          ? employeeTokens.errorMessage
          : "Fetching employee tokens failed."
      );
    }

    if (notificationsResponse.hasError) {
      return toast.error(
        notificationsResponse.errorMessage
          ? notificationsResponse.errorMessage
          : "Fetching notifications failed."
      );
    }
    setEmployeeTokenCount(employeeTokens.data);
    setSystemNotifications(notificationsResponse.notifications);

    toast.success(response.data.value);
    closeModal();

    if (subscribedBenefitGroupsResponse.hasError) {
      return toast.error(
        subscribedBenefitGroupsResponse.errorMessage
          ? subscribedBenefitGroupsResponse.errorMessage
          : "Fetching Subscribed Benefit groups failed."
      );
    }

    if (benefitGroupResponse.hasError) {
      return toast.error(
        benefitGroupResponse.errorMessage
          ? benefitGroupResponse.errorMessage
          : "Fetching Benefit group failed."
      );
    }

    // Todo ostaviti prethodni state ako fetch failuje
    setSubscribedBenefitGroups(subscribedBenefitGroupsResponse.groups);
    updateBenefitGroup(benefitGroupResponse);

    const metadata = {
      user_id: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      benefit_group_name: benefitGroup.name,
      benefit_name: benefit.title,
      benefit_price: benefit.tokenPrice,
      quantity: quantity,
      created_at: new Date()
    };
    window.Intercom("trackEvent", "subscribed-to-benefit", metadata);
  };

  const unsubscribeToBenefit = async () => {
    setIsButtonsDisabled(true);
    setIsLoading(true);

    const response = await api.subscribeOrUnsubscribeToBenefit({
      benefitGroupId: benefitGroup.id,
      subscribe: false,
      benefitId: benefit.id
    });
    if (response.hasError) {
      setIsButtonsDisabled(false);
      setIsLoading(false);

      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Subscribing to benefit failed."
      );
    }

    const [
      subscribedBenefitGroupsResponse,
      employeeTokens
    ] = await Promise.all([
      api.getSubscribedBenefits(),
      employeeService.getEmployeeTokenCount()
    ]);

    const benefitGroupResponse = await benefitGroupService.refreshBenefitGroup(
      benefitGroup.id
    );
    if (benefitGroupResponse.hasError) {
      return toast.error(
        benefitGroupResponse.errorMessage
          ? benefitGroupResponse.errorMessage
          : "Fetching Benefit group failed."
      );
    }

    if (subscribedBenefitGroupsResponse.hasError) {
      return toast.error(
        subscribedBenefitGroupsResponse.errorMessage
          ? subscribedBenefitGroupsResponse.errorMessage
          : "Fetching Subscribed Benefit groups failed."
      );
    }

    if (employeeTokens.hasError) {
      return toast.error(
        employeeTokens.errorMessage
          ? employeeTokens.errorMessage
          : "Fetching employee tokens failed."
      );
    }
    setEmployeeTokenCount(employeeTokens.data);

    toast.success(response.data.value);
    closeModal();

    setSubscribedBenefitGroups(subscribedBenefitGroupsResponse.groups);
    updateBenefitGroup(benefitGroupResponse);
  };

  /**
   * Handles setting of quantity from on change event in input field.
   * Sets quantity to state.
   * @param {Event} e
   */
  const handleSetQuantity = e => {
    let inputValue;
    if (e.target.validity.valid) {
      let replacedValue = e.target.value.replace(/[^0-9]*/g, "");
      inputValue = parseInt(replacedValue);
    }
    if (isNaN(inputValue)) {
      inputValue = 1;
    }

    if (inputValue >= 999) {
      inputValue = 999;
    }

    setQuantity(inputValue);
  };

  const handleKeyDown = e => {
    if (e.key === "." || e.key === "," || e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  return (
    <>
      {benefit.isSubscribed && benefit.tokenPrice > 0 ? (
        <button
          type="button"
          className="subscribedButton"
          onMouseEnter={() => setIsSubscribedButtonHovered(true)}
          onMouseLeave={() => setIsSubscribedButtonHovered(false)}
          disabled={IsButtonsDisabled}
          onClick={unsubscribeToBenefit}
        >
          <div className="circle">
            {isSubscribedButtonHovered ? (
              <hr className="unsubscribeLine" />
            ) : (
              <CheckIcon fill="#1568bf" height="8" />
            )}
          </div>
          <p className="subscribedText">
            {isSubscribedButtonHovered ? "Unsubscribe" : "Subscribed"}
          </p>
        </button>
      ) : (
        <>
          {!isEmpty(benefit.quantity) && (
            <>
              <div className="errorMsg benefitErrorMsg">
                {!IsButtonsDisabled &&
                  calculateBenefitPrice() > user.remainingTokens &&
                  `You don’t have enough tokens to subscribe.`}
              </div>
              <div
                style={{
                  width: 215,
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TokenIcon />
                  <p className="benefitPrice">{calculatedPrice}</p>
                </div>
                <BenefitSubscribeQuantity
                  setQuantity={setQuantity}
                  isPreview={isPreview}
                  handleKeyDown={handleKeyDown}
                  handleSetQuantity={handleSetQuantity}
                  quantity={quantity}
                />
              </div>
            </>
          )}
          {benefitGroupType === PUBLIC ? (
            <button
              className="unsubscribedButton"
              type="button"
              disabled={
                isPreview ||
                IsButtonsDisabled ||
                calculatedPrice > user.remainingTokens
              }
              onClick={subscribeToBenefit}
            >
              {benefit.expiration === BenefitExpirationTypeEnum.ONE_TIME &&
              status === PENDING_DEACTIVATION &&
              benefit.shouldBeIndicatedAsActive
                ? "Resubscribe"
                : "Subscribe"}
            </button>
          ) : benefitGroupType === LOCAL && benefit.tokenPrice > 0 ? (
            <button
              className="unsubscribedButton"
              type="button"
              disabled={
                isPreview ||
                IsButtonsDisabled ||
                calculatedPrice > user.remainingTokens
              }
              onClick={subscribeToBenefit}
            >
              {benefit.expiration === BenefitExpirationTypeEnum.ONE_TIME &&
              status === PENDING_DEACTIVATION &&
              benefit.shouldBeIndicatedAsActive
                ? "Resubscribe"
                : "Subscribe"}
            </button>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    benefitGroup:
      state.employeeBenefitsPage.pageFrontEndStates.chosenBenefitGroup,
    appliedChosenCategoryIds:
      state.employeeBenefitsPage.filters.appliedChosenCategoryIds,
    appliedCityIds: state.employeeBenefitsPage.filters.cityIds,
    appliedRemoteFilter: state.employeeBenefitsPage.filters.isRemote,
    appliedChosenPriceRange:
      state.employeeBenefitsPage.filters.appliedChosenPriceRange
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeBenefitGroupModal: () =>
      dispatch(actionCreators.closeBenefitGroupModal()),
    setBenefitGroupsAndSubscribedBenefitGroups: (
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsPages,
      subscribedBenefitGroups
    ) =>
      dispatch(
        actionCreators.setBenefitGroupsAndSubscribedBenefitGroups(
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsPages,
          subscribedBenefitGroups
        )
      ),
    setEmployeeTokenCount: payload =>
      dispatch(appActionCreators.setEmployeeTokens(payload)),
    setSystemNotifications: notifications =>
      dispatch(appActionCreators.setSystemNotifications(notifications)),
    updateBenefitGroup: benefitGroup =>
      dispatch(actionCreators.updateBenefitGroup(benefitGroup)),
    setSubscribedBenefitGroups: benefitGroups =>
      dispatch(actionCreators.setSubscribedBenefitGroups(benefitGroups))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitSubscribeButton);
