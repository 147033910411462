import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Item from "./Item";

const ListStyles = styled.div`
  width: 100%;
  height: 286px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px;

  .selectedItem {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  }
`;

const List = ({
  list = [],
  itemOnClick,
  animationId,
  animationClassName,
  editButton,
  chosenItem,
  removeButton,
  openModalPreviewModalAction = () => { },
  isBenefitGroup = false
}) => (
  <ListStyles id={animationId}>
    {list.map(item => (
      <Item
        key={item.id}
        selectedItem={item.id === chosenItem}
        isDraft={item.isDraft}
        title={item.title || item.name}
        description={
          item.summary ||
          `${item.benefits ? item.benefits.length : 0} Benefits`
        }
        taxes={item.taxes}
        onCardClick={() => itemOnClick(item.id)}
        editButton={() => editButton(item.id)}
        removeButton={() => removeButton(item)}
        openModalPreviewModalAction={() => openModalPreviewModalAction(item.id)}
        isBenefitGroup={isBenefitGroup}
      />
    ))}
  </ListStyles>
);

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()),
  itemOnClick: PropTypes.func,
  chosenItem: PropTypes.number,
  animationId: PropTypes.string,
  animationClassName: PropTypes.string,
  editButton: PropTypes.func,
  removeButton: PropTypes.func
};

export default List;
