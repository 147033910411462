import axios from "axios";
import {
  getItem,
  removeAuthItemsFromLocalStorage,
  setItem
} from "../localStorage.service";
import { BASE_API_URL } from "../../constants/api.constants";
import {
  BENEFIT_REFRESH_TOKEN,
  BENEFIT_TOKEN
} from "../../constants/localStorage.constants";

class ApiService {
  constructor() {
    let service = axios.create({
      baseURL: BASE_API_URL
    });
    service.interceptors.request.use(
      this.handleReqInterceptorSuccess,
      this.handleReqInterceptorError
    );
    service.interceptors.response.use(
      this.handleResInterceptorSuccess,
      this.handleResInterceptorError
    );
    this.service = service;
    this.isIssuingRefreshToken = false;
  }

  handleReqInterceptorSuccess(request) {
    if (request.baseURL === BASE_API_URL && !request.headers.Authorization) {
      const token = getItem(BENEFIT_TOKEN);

      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
    }
    return request;
  }

  handleReqInterceptorError = error => {
    console.log("request interceptor error", error.response);
    return Promise.reject(error);
  };

  handleResInterceptorSuccess(response) {
    return response;
  }

  handleResInterceptorError = async error => {
    const originalRequest = error.config;

    if ([401].includes(error.response.status) && !originalRequest._retry) {
      originalRequest._retry = true;

      if (this.isIssuingRefreshToken) {
        await this.waitForRefreshToken();
        return this.service(originalRequest);
      }

      await this.refreshAccessToken(originalRequest);
      return this.service(originalRequest);
    }
    return Promise.reject(error);
  };

  // Function for pausing code until refresh token is resolved in another api instance
  async waitForRefreshToken() {
    if (!this.isIssuingRefreshToken) return;
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.waitForRefreshToken();
  }

  logout() {
    // First, we remove everything auth related from local storage.
    removeAuthItemsFromLocalStorage();
    // Then we redirect to / route because login page is on it, in the public routes array.
    // We use the # here in between the slashes because we use the hash router on the app
    // so we have to include it in the redirect to be sure we went to the right page
    window.location.href = "/#/";
    // Finally we reload the page for the redux state to reset itself to initial state.
    window.location.reload(true);
  }

  /**
   * Checks if response status is a success (between 200 and 299).
   * @param {number} status
   * @returns {boolean}
   */
  isStatusSuccess(status) {
    return status >= 200 && status < 300;
  }

  async refreshAccessToken(request) {
    this.isIssuingRefreshToken = true;
    const refreshToken = getItem(BENEFIT_REFRESH_TOKEN);

    if (!refreshToken) {
      this.logout();
    }

    try {
      const response = await axios.post(
        `${BASE_API_URL}/users/refresh-tokens`,
        { refreshToken }
      );

      if (!this.isStatusSuccess(response.status)) {
        this.logout();
      }

      const newToken = response.data.auth.access_token;
      const newRefreshToken = response.data.auth.refresh_token;
      request.headers.Authorization = `Bearer ${newToken}`;
      setItem(BENEFIT_TOKEN, newToken);
      setItem(BENEFIT_REFRESH_TOKEN, newRefreshToken);
    } catch (error) {
      this.logout();
    }
    this.isIssuingRefreshToken = false;
  }

  async handleApiCall(apiCall) {
    try {
      const response = await apiCall();
      return response;
    } catch (error) {
      return {
        hasError: true,
        error: error.response,
        errorMessage: error.response?.data?.error.message
      };
    }
  }

  async get(path) {
    return this.handleApiCall(() => this.service.get(path));
  }

  async post(path, payload) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "POST",
        url: path,
        data: payload
      })
    );
  }

  async put(path, payload) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "PUT",
        url: path,
        data: payload
      })
    );
  }

  async patch(path, payload) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "PATCH",
        url: path,
        data: payload
      })
    );    
  }

  async delete(path) {
    return this.handleApiCall(() => this.service.delete(path));
  }
}

export const apiService = new ApiService();
