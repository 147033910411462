import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { hrEmployeesInitalState, hrEmployeesReducer } from './hrEmployees.reducer'
import { useHrEmployeesActions } from './hrEmployees.actions'

const HrEmployeesContext =
createContext(...hrEmployeesInitalState)

const HrEmployeesProvider = ({ children }) => {
	const [hrEmployeeState, dispatchHrEmployeeState] =
		useReducer(hrEmployeesReducer, hrEmployeesInitalState)
	const hrEmployeesActions =
		useHrEmployeesActions(dispatchHrEmployeeState)

	return (
		<HrEmployeesContext.Provider
			value={{
				hrEmployeeState,
				hrEmployeesActions
			}}
		>
			{children}
		</HrEmployeesContext.Provider>
	)
}

HrEmployeesProvider.propTypes = {
	children: PropTypes.node
}

export { HrEmployeesProvider, HrEmployeesContext }
