import React, { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Components
import { DotsIcon, UserDisabledIconSvg } from '../../assets/Icons';
import ListItemStyles from './ListItemStyles';
import ListItemExpandedStyles from './ListItemExpandedStyles';
import ChevronIconDownSvg from '../../assets/Icons/ChevronIconDownSvg';
import CheckboxInput from '../checkboxInput'

// Services
import getInitals from '../../services/getInitals';
import * as usersService from "../../services/api/admin/users.service";
import { isEmpty } from '../../services/general.utils';
import { formatDateForPlaceholder } from '../../services/date.utils';

const ListItem = ({
  fullName,
  isRowDisabled,
  isFirstLetter = false,
  roles,
  company,
  email,
  isActionsEnabled = true,
  userId,
  isEditActionVisable = true,
  employeeId,
  isResendVerificationEnabled = true,
  shouldShowDeletePopup = true,
  isDeleteActionVisable = true,
  openDisableUserPopup = () => { },
  employeeData = {},
  fetchData = () => { },
  setSelectedNumber,
  checkedMap,
  setCheckedMap,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const init = checkedMap.has(userId) ? checkedMap.get(userId) : false;
  const [isChecked, setIsChecked] = useState(init);

  const myTooltip = useRef();
  const history = useHistory();

  const onClickDots = () => {
    setIsActive(true);
    ReactTooltip.rebuild();
  };

  const handleBlur = () => {
    setIsActive(false);
    ReactTooltip.rebuild();
  };

  const handleExpand = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(!isOpen);
  }

  const resendVerificationLink = async (e, userId) => {
    e.stopPropagation();
    const response = await usersService.resendVerificationLink(
      userId
    );
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Verification link was not sent, please contact support."
      );
    }

    toast.success(response.data.message);
  };

  const handleDisableUserPopupOpen = (e, userId) => {
    e.stopPropagation();
    openDisableUserPopup(userId);
  }

  /**
   * Handles user enabling.
   * Sends request to update user enabled status.
   * Refetches data.
   * @param {Event} e 
   */
  const handleUserEnable = async (e, userId) => {
    e.stopPropagation();

    const response = await usersService.enableUser(userId);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Enabling user failed."
      );
    }

    toast.success(response.data.message);

    fetchData();
  }

  /**
   * Handles the behavior when the checkedMap is changed
   * If user in checkedMap is true, set isChecked to true, otherwise false.
   * Calculate and set total number of selected users
   */
  useEffect(() => {    
    let total = 0;
    for (const x of checkedMap.values()) {
      x && total ++;
    };

    setSelectedNumber(total);
    checkedMap.has(userId) && setIsChecked(checkedMap.get(userId));    
  }, [checkedMap]);

  const handleCheckedOnChange = () => {
    const checked = new Map(checkedMap);
    checked.set(userId, !isChecked);

    setCheckedMap(checked);
    setIsChecked(!isChecked);
  };

return (
    <>
      <ListItemStyles background={isChecked && "#F5FAFF"} border={isOpen && "1px solid transparent"} >
        <div className="checkboxCol">
          {!isRowDisabled && (
            <CheckboxInput 
              onChange={handleCheckedOnChange} 
              isChecked = {isChecked} 
              name={userId.toString()}
              width="18px"
              height="18px"
            />
          )}
        </div>
        <div className="list" onClick={e => handleExpand(e)}>            
          <div className="nameCol">
            <ChevronIconDownSvg isOpen={isOpen} fill={isOpen ? "#668CCC" : "#DBDBDB"} />
            <div className="employeeAvatar">
              {getInitals(fullName, isFirstLetter)}
            </div>
            <span>{fullName !== "" ? fullName : "Unknown"}</span>
            {isRowDisabled && (
              <>
                <span
                  data-tip={`${userId}|${isRowDisabled}`}
                  data-for={`userDisabled-${userId}`}
                  style={{ position: 'absolute', top: '20px', left: '58px' }}
                >
                  <UserDisabledIconSvg />
                </span>
                <ReactTooltip
                  id={`userDisabled-${userId}`}
                  place="top"
                  effect="solid"
                  className="email-tooltip list-disabled-tooltip"
                  backgroundColor="#000000"
                  borderColor="#000000"
                  arrowColor="#000000"
                >
                  <span>User is disabled</span>
                </ReactTooltip>
              </>
            )}
          </div>
          <div className="roles">
            {roles}
          </div>
          <div className="company">
            {company}
          </div>
          <div className="email">
            {email}
          </div>
          {isActionsEnabled && (
            <>
              <button
                type="button"
                className="actionsCol"
                data-tip={`${userId}|${isRowDisabled}|${employeeId}`}
                data-event={isActive ? "focus" : "click"}
                data-event-off={!isActive ? "focusout" : ""}
                onClick={e => {
                  e.stopPropagation();
                  onClickDots();
                }}
                onBlur={() => handleBlur()}
              >
                <DotsIcon transform="rotate(90deg)" transformOrigin="90% 90%"/>
              </button>
              <ReactTooltip
                place="bottom"
                effect="solid"
                // eslint-disable-next-line
                ref={ref => (myTooltip.current = ref)}
                className="tooltip"
                getContent={dataTip => {
                  if (!dataTip) {
                    return "";
                  }
                  const [
                    userId,
                    isDisabledParam,
                    employeeId
                  ] = dataTip.split("|");

                  return (
                    <div className="tooltipContentWrapper">
                      {isEditActionVisable && (
                        <button
                          type="button"
                          className="button"
                          onClick={e => {
                            e.stopPropagation();
                            history.push(`users/edit/${userId}/${employeeId}`);
                          }}
                        >
                          Edit
                        </button>
                      )}
                      {isDisabledParam === 'true' && (
                        <button
                          type="button"
                          className="button"
                          onClick={async (e) => handleUserEnable(e, userId)}
                        >
                          Enable user
                        </button>
                      )}
                      {isResendVerificationEnabled && (
                        <button
                          type="button"
                          className="button"
                          onClick={async (e) => resendVerificationLink(e, userId)}
                        >
                          Resend verification link
                        </button>
                      )}
                      {isDeleteActionVisable && shouldShowDeletePopup && isDisabledParam === 'false' && (
                        <button
                          type="button"
                          className="button"
                          onClick={async (e) => handleDisableUserPopupOpen(e, userId)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  );
                }}
                border
                clickable
              />
            </>
          )}
        </div>
      </ListItemStyles>
      <ListItemExpandedStyles onClick={e => handleExpand(e)} backgroundOpen={isChecked && "#F5FAFF"} >
        {isOpen && (
          <div className="listItemExpanded open">
            <div className="genderAndLocation">
              <div className="expandedListItemDataContainer gender">
                <span className="expandedListItemLabel">
                  Gender
                </span>
                <span className="genderValue">
                  {!isEmpty(employeeData) && !isEmpty(employeeData.gender) ? employeeData.gender : '-'}
                </span>
              </div>
              <div className="expandedListItemDataContainer locations">
                <span className="expandedListItemLabel">
                  Locations
                </span>
                <span>
                  {!isEmpty(employeeData) && !isEmpty(employeeData.cities) ? employeeData.cities.map(l => l.name).join(", ") : '-'}
                </span>
              </div>
            </div>
            <div className="birthDayAndEnrolldateWrapper">
              <div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Birthdate
                  </span>
                  <span>
                    {!isEmpty(employeeData) && !isEmpty(employeeData.bornDate) ? formatDateForPlaceholder(employeeData.bornDate) : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Enroll date
                  </span>
                  <span>
                    {!isEmpty(employeeData) && !isEmpty(employeeData.firstDayAtWork) ? formatDateForPlaceholder(employeeData.firstDayAtWork) : '-'}
                  </span>
                </div>
              </div>
            </div>
            <div className="teamAndPositionWrapper">
              <div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Team
                  </span>
                  <span>
                    {!isEmpty(employeeData) && !isEmpty(employeeData.team) ? employeeData.team : '-'}
                  </span>
                </div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Relationship
                  </span>
                  <span className="genderValue">
                    {!isEmpty(employeeData) && !isEmpty(employeeData.relationship) ? employeeData.relationship : '-'}
                  </span>
                </div>
              </div>
            </div>            
            <div className="contactWrapper">
              <div className="expandedListItemDataContainer">
                <span className="expandedListItemLabel">
                  Contact
                </span>
                <span>
                  {!isEmpty(employeeData) && !isEmpty(employeeData.contact) ? employeeData.contact : '-'}
                </span>
              </div>
            </div>
          </div>
        )}
      </ListItemExpandedStyles>    
    </>
  )
}

export default ListItem;