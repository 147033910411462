import styled from 'styled-components'

export const ButtonHolder = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  background: ${({ outline, disabled, transparent }) => {
		if (disabled) {
			return '#d8d8d8'
		}
		if (outline) {
			return '#fff'
		}
		if (transparent) {
			return 'transparent'
		}
		return '#1568bf'
	}};
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ outline }) => (outline ? 'solid 1px #1568bf' : 'solid 1px transparent')};
  outline: none;
  color: ${({ outline, disabled, color }) => {
		if (disabled) {
			return '#989898'
		}
		if (outline) {
			return '#1568bf'
		}
		if (color) {
			return color
		}
		return '#fff'
	}};
  cursor: pointer;
	pointer-events: ${({ disabled }) => disabled && 'none'};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
	margin: ${({ margin }) => margin};
	height: ${({ height }) => height};
	width: ${({ width }) => width};
	border-right: ${({ borderRight }) => borderRight};;


	@media only screen and (max-width: 600px) {
		margin: ${({ mobileMargin }) => mobileMargin};
		width: ${({ mobileWidth }) => mobileWidth};
	}
`
