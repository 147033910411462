import React from "react";
import { connect } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// assets
import { LogoutIcon } from "../../assets/Icons";
//components
import { CompanyThemeLogo } from "../../components";
//styles
import SidebarMenuStyles from "./SidebarMenuStyles";
import SidebarMenuTab from "./sidebarMenuTab";
//constants
import { BENEFIT_ROLE } from "../../constants/localStorage.constants";
//config
import { navigationConfig } from "../../config/navigation.config";
//actions
import * as actionCreators from "../../actions/app.actions";
import * as actionHRCreators from "../../actions/hr/employees.actions"
//services
import { filterArrayByValueOfKey, isEmpty } from '../../services/general.utils';
//hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const SidebarMenu = ({ logout, user, isSidebarMenuOpen, toggleSidebarMenu }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { width} = useWindowDimensions();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const isShowSidebar = isEmpty(pathname.match("employees")) 
  || (!isEmpty(pathname.match("employees")) && (isSidebarMenuOpen || (width >= 767)));

  return (
    /**
    * SidebarMenu is closed by default when screen size < 767px in the HR/Employees page
     */
    isShowSidebar
    && <SidebarMenuStyles>
         <div className="darkOverlay" />
         <div className="logoContainer">                      
      {user?.authenticatedRole &&  
           <>              
            {!isEmpty(pathname.match("employees")) && (width < 767) && 
             <button className="closeSidebarMenuButton" onClick={() => toggleSidebarMenu(isSidebarMenuOpen)}> X </button>               
            }
             <div onClick={() => history.push("/")} style={{ cursor: "pointer" }}>
               <CompanyThemeLogo 
                 color="#fff" 
                 theme={filterArrayByValueOfKey(user.themes, "mode", user.mode)[0]}
                 colorTheme = "#fff"/>              
             </div>
           </>
      }
         </div>
      {user && user?.authenticatedRole &&
         <div className="sidebarTabsContainer">
          {navigationConfig[user?.authenticatedRole?.key].map(link => (
            <NavLink
              exact
              to={link.path}
              key={link.path}
              style={{ textDecoration: "none" }}
              className="navLink"
              activeClassName="isActive"
            >
              <SidebarMenuTab
                linkName={link.title}
                Icon={link.icon}
                paraIconLeftMargin="14.1px"
              />
            </NavLink>
          ))}
           <SidebarMenuTab
             linkName="Logout"
             Icon={LogoutIcon}
             paraIconLeftMargin="18px"
             customCssClass="logoutTab navLink"
             onClick={() => {
              localStorage.removeItem(BENEFIT_ROLE);
              logout();
              isAuthenticated && instance.logoutPopup();            
              history.replace("/");
             }}
           />
         </div>}
       </SidebarMenuStyles>
  );
};

SidebarMenu.propTypes = {
  is404: PropTypes.bool,
  isVisible: PropTypes.bool,
  allowedModules: PropTypes.arrayOf(PropTypes.shape()),
  path: PropTypes.string,
  isSidebarMenuOpen: PropTypes.bool,
  toggleSidebarMenu: PropTypes.func,
  logout: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    isSidebarMenuOpen: state.employees.isSidebarMenuOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
    toggleSidebarMenu: isSidebarOpen => dispatch(actionHRCreators.toggleSidebarMenu(isSidebarOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
