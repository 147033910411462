import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// components
import { Header, Card, Button } from "../../../../components";
import BasicInfoSection from "../BasicInfoSection/BasicInfoSection";
import CompanyTags from "../CompanyTagsSection/CompanyTagsSection";
import DetailsSection from "../DetailsSection/DetailsSection";
// entity
import { Company } from "../../../../models/domain";
// styles
import EditCompanyStyles from "./EditCompanyStyles";
import { override } from "../../../../services/loadingOverride.styles";
// services
import { validateCompanyForm } from "../../../../services/validations/superAdmin/company.validations";
import * as companiesService from "../../../../services/api/admin/companies.service";
// context
import { UserContext } from "../../../../modules/user/context/user.context";

const EditCompany = ({ match }) => {
  const [company, setCompany] = useState(new Company());
  const [isLoading, setIsLoading] = useState(true);
  const [disableButton, setDisableButton] = useState(false);

  // const { user } = useContext(UserContext)
  const history = useHistory();
  const isMounted = useRef(true);

  const fetchCompany = async () => {
    const response = await companiesService.getSelectedCompany(match.params.id);

    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : "Fetch company failed."
      );
    }

    if (isMounted.current) {
      setCompany(response);
      setIsLoading(false);
      setTimeout(() => {
        const editCompany = document.getElementById("editCompany");
        if (isMounted.current && editCompany) {
          editCompany.style.opacity = 1;
        }
      }, 100);
    }
  };
  useEffect(() => {
    fetchCompany();
    return () => () => {
      isMounted.current = false;
    };
  }, []);

  const handleUpdateCompanyError = ({ error }) => {
    const { data } = error;
    if (data.errors) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
      if (data.errors.address) {
        toast.error(`${data.error.message}: ${data.errors.address.keys[0]}`);
      }
      if (data.errors.pib) {
        toast.error(`${data.error.message}: ${data.errors.pib.keys[0]}`);
      }
      if (data.errors.idNumber) {
        toast.error(`${data.error.message}: ${data.errors.idNumber.keys[0]}`);
      }
      if (data.errors.contact) {
        toast.error(`${data.error.message}: ${data.errors.contact.keys[0]}`);
      }
      if (data.errors.invoiceEmail) {
        toast.error(
          `${data.error.message}: ${data.errors.invoiceEmail.keys[0]}`
        );
      }
      if (data.errors.paymentDeadline) {
        toast.error(
          `${data.error.message}: ${data.errors.paymentDeadline.keys[0]}`
        );
      }
      if (data.errors.vatEnabled) {
        toast.error(`${data.error.message}: ${data.errors.vatEnabled.keys[0]}`);
      }
      if (data.errors.photo) {
        toast.error(`${data.errors.photo.keys[0]}`);
      }
    } else {
      toast.error(data.error.message);
    }
  };

  const updateCompanySubmit = async values => {
    setDisableButton(true);
    const response = await companiesService.updateCompany(values);
    setDisableButton(false);

    if (response.hasError) {
      return handleUpdateCompanyError(response);
    }

    history.push("/companies");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: company,
    validate: validateCompanyForm,
    onSubmit: updateCompanySubmit
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditCompanyStyles>
      <div className="editCompanyContainer">
        <Header headerTitle="Edit Company" />
        <div className="editCompanyContent">
          {isLoading ? (
            <div
              style={{
                height: "calc(100vh - 180px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <BeatLoader
                css={override}
                size={25}
                color="#123abc"
                loading={isLoading}
              />
            </div>
          ) : (
            <Card id="editCompany">
              <form
                autoComplete="off"
                encType="multipart/form-data"
                onSubmit={e => handleSubmit(e)}
              >
                <BasicInfoSection
                  isFormDisplay
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <CompanyTags
                  isFormDisplay
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <DetailsSection
                  isFormDisplay
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setFieldError={setFieldError}
                />
                <div style={{ display: "flex" }}>
                  {disableButton ? (
                    <Button width="130px" disabled margin="0 30px 0 0">
                      Please wait
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      width="130px"
                      margin="0 30px 0 0"
                      padding="10px 25px"
                    >
                      Update
                    </Button>
                  )}
                  <Button
                    onClick={() => history.push("/companies")}
                    width="130px"
                    margin="0"
                    outline
                    padding="10px 25px"
                  >
                    Back
                  </Button>
                </div>
              </form>
            </Card>
          )}
        </div>
      </div>
    </EditCompanyStyles>
  );
};

EditCompany.propTypes = {
  match: PropTypes.shape()
};

export default EditCompany;
