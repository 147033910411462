import * as actionTypes from "../../constants/actionTypes/employee/employeeBenefits.constants";
import { updateObject } from "../../services/updateObject";
import { priceRangeOptions } from "../../builders/employee/priceRangeOptions.config";
import { PriceRange } from "../../models/domain";
import { PUBLIC } from "../../builders/benefitGroups/benefitGroup.types";

const initialState = {
  benefitsBrowse: {
    benefitGroups: [],
    benefitGroupsPage: 1,
    benefitGroupsCount: 0,
    benefitGroupsTotalPages: 0
  },
  benefitsCompany: {
    benefitGroups: [],
    benefitGroupsPage: 1,
    benefitGroupsCount: 0,
    benefitGroupsTotalPages: 0
  },
  subscribedBenefits: {
    subscribedBenefitGroups: [],
    isConditionAllBenefits: true,
    isNextMonthTooltipOpen: false
  },
  filters: {
    categories: [],
    chosenCategoryIds: [],
    appliedChosenCategoryIds: [],
    cityIds: [],
    expirations: [],
    isRemote: false,
    chosenPriceRange: new PriceRange(),
    appliedChosenPriceRange: new PriceRange(),
    search: "",
    order: "",
    key: "",
    isApplyFilterClicked: false
  },
  pageFrontEndStates: {
    isLoading: true,
    isPageLoading: false,
    isSubscribedBenefitGroupsVisible: false,
    benefitGroupType: PUBLIC,
    isModalOpen: false,
    chosenBenefitGroup: null
  }
};

const employeeBenefitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITAL_STATE:
      return employeeBenefitsSetInitalState(state, action);
    case actionTypes.UPDATE_BENEFIT_GROUP:
      return updateBenefitGroup(state, action);
    case actionTypes.SET_IS_SUBSCRIBED_BENEFIT_GROUPS_VISIBLE:
      return setIsSubscribedBenefitGroupsVisible(state, action);
    case actionTypes.SET_BENEFIT_GROUP_TYPE:
      return setBenefitGroupType(state, action);
    case actionTypes.SET_IS_INITIAL_STATE_SET:
      return setIsInitialStateSet(state, action);
    case actionTypes.SET_IS_PAGE_LOADING:
      return setIsPageLoading(state, action);
    case actionTypes.SET_CHOSEN_CATEGORY_IDS:
      return setChosenCategoryIds(state, action);
    case actionTypes.SET_CITY_IDS_FILTERS:
      return setCitiesFilter(state, action);
    case actionTypes.SET_REMOTE_FILTER:
      return setRemoteFilter(state, action);
    case actionTypes.SET_FILTERS_PRICE_RANGE:
      return setFiltersPriceRange(state, action);
    case actionTypes.SET_BENEFIT_EXPIRATION_TYPES_FILTERS:
      return setBenefitExpirationTypesFilter(state, action);
    case actionTypes.SET_SEARCH_FILTERS:
      return setSearchFilter(state, action);
    case actionTypes.SET_ORDER_SORT:
      return setOrderSort(state, action);
    case actionTypes.SET_KEY_SORT:
      return setKeySort(state, action);
    case actionTypes.SET_ADITIONAL_BENEFIT_GROUPS:
      return setAditionalBenefitGroups(state, action);
    case actionTypes.SET_BENEFIT_GROUPS_PAGE:
      return setBenefitGroupsPage(state, action);
    case actionTypes.APPLY_BENEFIT_GROUPS_FILTERS:
      return applyBenefitGroupsFilters(state, action);
    case actionTypes.RESET_BENEFIT_GROUPS_FILTERS:
      return resetBenefitGroupFilters(state, action);
    case actionTypes.RESET_BENEFIT_GROUPS_FILTERS_TO_DEFAULT:
      return resetBenefitGroupFiltersToDefault(state, action);
    case actionTypes.OPEN_BENEFIT_GROUP_MODAL:
      return openBenefitGroupModal(state, action);
    case actionTypes.SET_CHOSEN_BENEFIT_GROUP:
      return setChosenBenefitGroup(state, action);
    case actionTypes.CLOSE_BENEFIT_GROUP_MODAL:
      return closeBenefitGroupModal(state, action);
    case actionTypes.SET_BENEFIT_GROUPS_AND_SUBSCRIBED_BENEFIT_GROUPS:
      return setBenefitGroupsAndSubscribedBenefitGroups(state, action);
    case actionTypes.SET_NEXT_MONTH_FILTER_CONDITION:
      return setIsConditionAllBenefits(state, action);
    case actionTypes.SET_NEXT_MONTH_TOOLTIP_IS_OPEN:
      return setIsNextMonthTooltipOpen(state, action);
    case actionTypes.SET_SUBSCRIBED_BENEFIT_GROUPS:
      return setSubscribedBenefitGroups(state, action);
  }
  return state;
};

const employeeBenefitsSetInitalState = (
  state,
  {
    benefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupsPage,
    categories
  }
) => {
  return updateObject(state, {
    benefitsBrowse: updateObject(state.benefitsBrowse, {
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsTotalPages,
      benefitGroupsPage
    }),
    filters: updateObject(state.filters, {
      categories
    }),
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isLoading: false
    })
  });
};

const updateBenefitGroup = (state, { benefitGroup }) => {
  return updateObject(state, {
    benefitsBrowse: updateObject(state.benefitsBrowse, {
      benefitGroups: state.benefitsBrowse.benefitGroups.map(group =>
        group.id === benefitGroup.id ? benefitGroup : group
      )
    })
  });
};

export const setSubscribedBenefitGroups = (
  state,
  { subscribedBenefitGroups }
) => {
  return updateObject(state, {
    subscribedBenefits: updateObject(state.subscribedBenefits, {
      subscribedBenefitGroups: subscribedBenefitGroups
    })
  });
};

const setIsSubscribedBenefitGroupsVisible = (state, { isVisible }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isSubscribedBenefitGroupsVisible: isVisible
    })
  });
};

const setBenefitGroupType = (state, { benefitGroupType }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      benefitGroupType: benefitGroupType
    })
  });
};

const setIsInitialStateSet = (state, { flag }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isInitialStateSet: flag
    })
  });
};

const setIsPageLoading = (state, { isLoading }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isPageLoading: isLoading
    })
  });
};

const setChosenCategoryIds = (state, { chosenCategoryIds }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      chosenCategoryIds
    })
  });
};

const setCitiesFilter = (state, { cityIds }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      cityIds
    })
  });
};

const setRemoteFilter = (state, { isRemote }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      isRemote
    })
  });
};

const setFiltersPriceRange = (
  state,
  { updatedPriceRangeOptions, chosenPriceRange }
) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      priceRangeOptions: updatedPriceRangeOptions,
      chosenPriceRange
    })
  });
};

const setBenefitExpirationTypesFilter = (state, { expirations }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      expirations
    })
  });
};

const setSearchFilter = (state, { search }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      search
    })
  });
};

const setOrderSort = (state, { order }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      order
    })
  });
};

const setKeySort = (state, { key }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      key
    })
  });
};

const setAditionalBenefitGroups = (
  state,
  {
    newBenefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupsPage,
    benefitGroupType
  }
) => {
  return benefitGroupType === PUBLIC
  ? updateObject(state, {
      benefitsBrowse: updateObject(state.benefitsBrowse, {
        benefitGroups: [
          ...state.benefitsBrowse.benefitGroups,
          ...newBenefitGroups
        ],
        benefitGroupsCount,
        benefitGroupsTotalPages,
        benefitGroupsPage,
        benefitGroupType
      })
    })
  : updateObject(state, {
      benefitsCompany: updateObject(state.benefitsCompany, {
        benefitGroups: [
          ...state.benefitsCompany.benefitGroups,
          ...newBenefitGroups
        ],
        benefitGroupsCount,
        benefitGroupsTotalPages,
        benefitGroupsPage,
        benefitGroupType
      })
    });
};

const setBenefitGroupsPage = (state, { benefitGroupsPage }) => {
  return updateObject(state, {
    benefitsBrowse: updateObject(state.benefitsBrowse, {
      benefitGroupsPage
    })
  });
};

const applyBenefitGroupsFilters = (
  state,
  { benefitGroups, benefitGroupsCount, benefitGroupsTotalPages, benefitGroupType }
) => {
  return benefitGroupType === PUBLIC
  ? updateObject(state, {
      benefitsBrowse: updateObject(state.benefitsBrowse, {
        benefitGroups,
        benefitGroupsCount,
        benefitGroupsTotalPages,
        benefitGroupsPage: 1,
        benefitGroupType
      }),
      filters: updateObject(state.filters, {
        appliedChosenCategoryIds: state.filters.chosenCategoryIds,
        appliedChosenPriceRange: state.filters.chosenPriceRange,
        isApplyFilterClicked: !state.filters.isApplyFilterClicked
      })
    })
  : updateObject(state, {
      benefitsCompany: updateObject(state.benefitsCompany, {
        benefitGroups,
        benefitGroupsCount,
        benefitGroupsTotalPages,
        benefitGroupsPage: 1,
        benefitGroupType
      }),
      filters: updateObject(state.filters, {
        appliedChosenCategoryIds: state.filters.chosenCategoryIds,
        appliedChosenPriceRange: state.filters.chosenPriceRange,
        isApplyFilterClicked: !state.filters.isApplyFilterClicked
      })
    });
};

const resetBenefitGroupFilters = (
  state,
  { benefitGroups, benefitGroupsCount, benefitGroupsTotalPages, benefitGroupType }
) => {
  return benefitGroupType === PUBLIC
  ? updateObject(state, {
      benefitsBrowse: updateObject(state.benefitsBrowse, {
        benefitGroups,
        benefitGroupsCount,
        benefitGroupsTotalPages,
        benefitGroupsPage: 1,
        benefitGroupType
      }),
      filters: updateObject(state.filters, {
        chosenCategoryIds: [],
        appliedChosenCategoryIds: [],
        chosenPriceRange: new PriceRange(),
        appliedChosenPriceRange: new PriceRange(),
        priceRangeOptions: priceRangeOptions,
        search: "",
        isApplyFilterClicked: !state.filters.isApplyFilterClicked
      })
    })
  : updateObject(state, {
      benefitsCompany: updateObject(state.benefitsCompany, {
        benefitGroups,
        benefitGroupsCount,
        benefitGroupsTotalPages,
        benefitGroupsPage: 1,
        benefitGroupType
      }),
      filters: updateObject(state.filters, {
        chosenCategoryIds: [],
        appliedChosenCategoryIds: [],
        chosenPriceRange: new PriceRange(),
        appliedChosenPriceRange: new PriceRange(),
        priceRangeOptions: priceRangeOptions,
        search: "",
        isApplyFilterClicked: !state.filters.isApplyFilterClicked
      })
    });
};

const resetBenefitGroupFiltersToDefault = state => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      chosenCategoryIds: [],
      appliedChosenCategoryIds: [],
      chosenPriceRange: new PriceRange(),
      appliedChosenPriceRange: new PriceRange(),
      priceRangeOptions: priceRangeOptions,
      search: "",
      isApplyFilterClicked: !state.filters.isApplyFilterClicked
    })
  });
};

const openBenefitGroupModal = (state, { chosenBenefitGroup }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isModalOpen: true,
      chosenBenefitGroup
    })
  });
};

const setChosenBenefitGroup = (state, { chosenBenefitGroup }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      chosenBenefitGroup
    })
  });
};

const closeBenefitGroupModal = (state, action) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isModalOpen: false,
      chosenBenefitGroup: null
    })
  });
};

const setBenefitGroupsAndSubscribedBenefitGroups = (
  state,
  {
    benefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    subscribedBenefitGroups
  }
) => {
  return updateObject(state, {
    benefitsBrowse: updateObject(state.benefitsBrowse, {
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsTotalPages,
      benefitGroupsPage: 1
    }),
    subscribedBenefits: updateObject(state.subscribedBenefits, {
      subscribedBenefitGroups
    })
  });
};

const setIsConditionAllBenefits = (state, { isConditionAllBenefits }) => {
  return updateObject(state, {
    subscribedBenefits: updateObject(state.subscribedBenefits, {
      isConditionAllBenefits,
      isNextMonthTooltipOpen: false
    })
  });
};

const setIsNextMonthTooltipOpen = (state, { isNextMonthTooltipOpen }) => {
  return updateObject(state, {
    subscribedBenefits: updateObject(state.subscribedBenefits, {
      isNextMonthTooltipOpen
    })
  });
};

export default employeeBenefitsReducer;
