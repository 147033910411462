// Models
import Benefit from "./Benefit.entity";
import Provider from "./Provider.entity";
import City from "./City.entity";
import Category from "./Category.entity";
import { JpgPhoto } from "../../services/global.entities";
// Utils
import { isEmpty } from "../../services/general.utils";
class BenefitGroup {
  constructor(benefitGroupData = {}) {
    this.id = benefitGroupData.id || null;
    this.name = benefitGroupData.name || "";
    this.type = benefitGroupData.type || "";
    this.categories = !isEmpty(benefitGroupData.categories)
      ? benefitGroupData.categories.map(category => new Category(category))
      : null;
    this.categoryIds = !isEmpty(benefitGroupData.categories)
      ? benefitGroupData.categories.map(category => category.id)
      : null;
    this.companyId = !isEmpty(benefitGroupData.companyId)
      ? benefitGroupData.companyId
      : null;
    this.capacity = benefitGroupData.capacity || null;
    this.taxes = benefitGroupData.taxes || "";
    if (
      typeof benefitGroupData.isRemote !== "undefined" ||
      typeof benefitGroupData.is_remote !== "undefined"
    ) {
      this.isRemote = benefitGroupData.isRemote || benefitGroupData.is_remote;
    } else {
      this.isRemote = true;
    }
    this.hasCapacity = benefitGroupData.hasCapacity || null;
    this.takenBenefits =
      benefitGroupData.takenBenefits || benefitGroupData.taken_benefits || 0;
    this.description = benefitGroupData.description || "";
    this.summary = benefitGroupData.summary || "";
    this.photo = benefitGroupData.Photo
      ? new JpgPhoto(benefitGroupData.Photo)
      : null;

    let provider = null;
    if (benefitGroupData.Provider)
      provider = new Provider(benefitGroupData.Provider);
    else if (benefitGroupData.Company)
      provider = new Provider(benefitGroupData.Company);
    this.provider = provider;

    this.benefits = benefitGroupData.Benefit
      ? benefitGroupData.Benefit.map(benefit => new Benefit(benefit))
      : [];
    this.availableTiers = benefitGroupData.AvailableBenefits
      ? benefitGroupData.AvailableBenefits.map(
          blockedBenefit => blockedBenefit.tierId
        )
      : [];
    this.status = benefitGroupData.Status?.status || null;
    this.statusPreview = benefitGroupData.Status?.statusPreview || null;
    this.shouldBeInAllBenefits =
      benefitGroupData.Status?.shouldBeInAllBenefits || null;
    this.cities = benefitGroupData.cities
      ? benefitGroupData.cities.map(city => new City(city))
      : [];
    this.availableCitiesCount =
      benefitGroupData.availableCitiesCount ||
      benefitGroupData.available_cities_count ||
      null;
    this.doesBenefitGroupHaveMcp =
      benefitGroupData.doesBenefitGroupHaveMcp ||
      benefitGroupData.does_benefit_group_have_mcp ||
      null;
    this.doesBenefitGroupHaveInstalments =
      benefitGroupData.doesBenefitGroupHaveInstalments ||
      benefitGroupData.does_benefit_group_have_instalments ||
      null;
    this.doesBenefitGroupHaveQuantity =
      benefitGroupData.doesBenefitGroupHaveQuantity ||
      benefitGroupData.does_benefit_group_have_quantity ||
      null;
    this.isBenefitGroupNew =
      benefitGroupData.isBenefitGroupNew ||
      benefitGroupData.is_benefit_group_new ||
      null;
    this.benefitGroupAvailable =
      benefitGroupData.benefitGroupAvailable ||
      benefitGroupData.benefit_group_available ||
      null;
    this.isDraft = !isEmpty(benefitGroupData.isDraft)
      ? benefitGroupData.isDraft
      : false;
    this.enableInstalments = !isEmpty(benefitGroupData.enableInstalments)
      ? benefitGroupData.enableInstalments
      : false;
    this.sumRatings = benefitGroupData.sumRatings;
    this.numberReviews = benefitGroupData.numberReviews;
    this.rating = benefitGroupData.rating;
    this.priceRange = benefitGroupData.priceRange || 0;
  }
}

export default BenefitGroup;
