import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import { Card } from "@material-ui/core";
// styles
import { PreviewModalStyles } from "./PreviewModalStyles";
// Services
import * as benefitGroupService from '../../../../services/api/admin/benefitGroups.service';
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";
import * as employeeBenefitsService from "../../../../services/api/employee/employeeBenefits.services";
// components
import { BenefitCard, Button } from "../../../../components";
import BenefitSubscribe from "../../../EmployeePages/EmployeeBenefits/benefitSubscribe";

const PreviewModal = ({ benefitGroupId, setChosenBenefitGroup, chosenBenefitGroup }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [usedBenefits, setUsedBenefits] = useState([]);

  /**
   * Returns benefit group for preview.
   * Sets benefit group to state.
   */
  const getBenefitGroupForPreview = async () => {
    const response = await benefitGroupService.getBenefitGroupForPreview(benefitGroupId);

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    setChosenBenefitGroup(response);
  }

  /**
   * Prepares div for download.
   * Formats div to canvas.
   * Calls downloadImage function.
   */
  const downloadPreview = async () => {
    setIsDisabled(true);
    const previewCanvas = document.getElementById('preview-wrapper');
    const canvas = await html2canvas(previewCanvas, {
      allowTaint: true,
      useCORS: true,
      windowHeight: (window.outerHeight + window.innerHeight) * 4,
      scale: 2
    });
    const image = canvas.toDataURL("image/png", 1.0);

    downloadImage(image, 'preview.png', previewCanvas);
  }

  const downloadImage = (blob, fileName, canvas) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display: none";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setIsDisabled(false);
  };

  const fetchAlreadyUsedBenefits = async () => {
    const response = await employeeBenefitsService.getAlreadyUsedBenefits(benefitGroupId);

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching Used Benefits failed."
      );
    }

    setUsedBenefits(response.usedBenefits);
  }

  useEffect(() => {
    getBenefitGroupForPreview();
    fetchAlreadyUsedBenefits();
  }, [])

  return (
    <PreviewModalStyles>
      <span>
        Preview
      </span>
      {chosenBenefitGroup &&
        (
          <div
            id="preview-wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px"
            }}>
            <BenefitCard
              benefitDetails={chosenBenefitGroup}
              onClick={() => { }}
              isPreview
            />
            <Card>
              <BenefitSubscribe isPreview maxHeight="60vh" usedBenefits={usedBenefits} />
            </Card>
          </div>
        )}
      <div className="action-buttons">
        <Button
          width="130px"
          margin="20px 0 0 0"
          onClick={() => downloadPreview()}
          disabled={isDisabled}
        >
          Download
        </Button>
      </div>
    </PreviewModalStyles >
  );
};

PreviewModal.propTypes = {
  benefitGroupId: PropTypes.number
};

const mapStateToProps = state => {
  return {
    chosenBenefitGroup: state.employeeBenefitsPage.pageFrontEndStates.chosenBenefitGroup
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setChosenBenefitGroup: (benefitGroup) =>
      dispatch(actionCreators.setChosenBenefitGroup(benefitGroup)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal);
